import { Datepicker, Popup } from "@mobiscroll/react";
import moment from "moment";
import React, { FC, useEffect, useMemo, useState } from "react";
import { useQuery } from "react-query";
import Select from "react-select";
import { KTSVG } from "../../../../_metronic/helpers";
import {
  getTaskCategory,
  getTaskType,
} from "../../../../_metronic/requests/FilterRequest";
import {
  createtaskApi,
  createticketApi, getToPlanTaskStatus
} from "../../../../_metronic/requests/PlanningRequest";
import { getResourceSetting } from "../../../../_metronic/requests/ResourceSettingRequest";
import SelectCustomLockOption, {
  formatOptionLabel,
} from "../../../components/modals/selectCustomLockOption";
import {
  setisCreated
} from "../../../counterSlice";
import { responsivePopup } from "../../../helpers/popup";
import { useAppDispatch } from "../../../hook";
type Props = {
  isOpen?: any;
  setIsCreateTaskOpen?: any;
  time_format?: any;
  timezone?: any;
  projectList?: any;
  phaseList?: any;
  general_settings?: any;
  filterArray?: any;
  prioritylist?: any;
  ticket_status?: any;
  issueslist?: any;
  subIssueslist?: any;
  worktype?: any;
  phase?: any;
  setIsPopupOpen?: any;
  setIsFormDirty?: any;
  createtype?: any;
  resource_settings?: any;
  toplanrefetch?: any;
  screenName?: any;
  locationShow?: any;
  locations?:any
  isEditScreen?: boolean,
  projectId?: any;
  companies?: any;
  setIsRefatchTable?: any;
  companyId?: any;
  phaseId?: any;
};
const TaskCreate: FC<Props> = ({
  isOpen,
  setIsCreateTaskOpen,
  setIsFormDirty,
  time_format,
  timezone,
  projectList,
  general_settings,
  filterArray,
  prioritylist,
  issueslist,
  subIssueslist,
  worktype,
  phase,
  setIsPopupOpen,
  createtype,
  toplanrefetch,
  screenName,
  locationShow,
  locations,
  isEditScreen,
  projectId,
  companies,
  setIsRefatchTable,
  companyId,
  phaseId,
}) => {
  
  const [createTask, setCreateTask] = useState<any>({
    type: createtype === "ticket" ? "ticket" : "task",
    title: "",
    company_id :companyId != null ? String(parseInt(companyId, 10) + 1) : "",
    projectID: projectId ? projectId : "",
    phaseID: phaseId !== 0 ? phaseId : "",
    isDraft : isEditScreen ? true : false,
  });
  let hideOptions = general_settings?.hide_options;
  const {
    data: taskCategory,
  } = useQuery(
    "taskCategory",
    () => {
      // debugger
      return getTaskCategory();
    },
    { cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false }
  );
  const [isValidCreateData, setIsValidCreateData] = useState<any>(true);
  const [showCreateError, setshowCreateError] = useState<any>(false);
  const dispatch = useAppDispatch();

  let TicketRoleArray: any = [];
  let taskRoleArray: any = [];
  let tasksacendaryRoleArray: any = [];

  const {
    data: task_status,
  } = useQuery(
    "todo-status",
    async () => {
      return await getToPlanTaskStatus("");
    },
    { cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false }
  );

  const {
    data: resource_settings,
  } = useQuery(
    "resources",
    async () => {
      return await getResourceSetting("");
    },
    { cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false }
  );

  const {
    data: taskType,
  } = useQuery(
    "taskType",
    () => {
      // debugger
      return getTaskType();
    },
    { cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false }
  );
  const createpopupvalidation = (event: any) => {
    let valid = 0;
    let requiredField: any = [];
    if (event.type == "task") {
      requiredField = [
        "type",
        "title",
        "start",
        "end",
        "taskCategoryID",
        "taskType",
        "company_id",
        "projectID",
        "billingCodeID",
        "status",
      ];
    } else if (event.type == "ticket") {
      requiredField = [
        "type",
        "title",
        "company_id",
        "billingCodeID",
        "status",
        "priority",
        "issueType",
        "subIssueType",
        "company_id",
      ];
    }
    event.projectID = isEditScreen ? projectId : event.projectID;
    requiredField.forEach((field: string) => {
      if (event[field] === undefined || event[field] === "") {
        valid += 1;
      }
    });
    if (
      taskRoleArray?.filter(
        (trole: any) =>
          parseInt(trole.resourceID) == parseInt(event.assignedResourceID) &&
          trole.has_license == false
      )?.length != 0
    ) {
      valid = 1;
    }
    if (
      tasksacendaryRoleArray
        .filter((e: any) =>
          event.secondary_resources
            ?.map((axisavailability: any) => axisavailability.value)
            .includes(e.value)
        )
        ?.filter((trole: any) => trole.has_license == false)?.length != 0
    ) {
      valid = 1;
    }
    setIsValidCreateData(!!valid);
  };
  const changeCreatetaskValue = (e: any, type: any) => {
    let event = JSON.parse(JSON.stringify(createTask));
    if (type == "title") {
      if (e.target.value?.length <= 255) {
        event.title = e.target.value;
      } else {
        event.title = e.target.value?.substr(0, 255);
      }
    }
    if (type == "start_date") {
      if (e.value != null) {
        let start = moment
          .tz(moment(e.value).format("YYYY-MM-DDTHH:mm"), timezone)
          .tz(timezone)
          .utc()
          .format("YYYY-MM-DDTHH:mm");
        event.start = start + "Z";
      }
    }
    if (type == "end_date") {
      if (e.value != null) {
        let end = moment
          .tz(moment(e.value).format("YYYY-MM-DDTHH:mm"), timezone)
          .tz(timezone)
          .utc()
          .format("YYYY-MM-DDTHH:mm");
        event.end = end + "Z";
      }
    }
    if (type == "category") {
      let tpriority = e[e.length - 1];
      if (createTask.type == "task") {
        event.taskCategoryID = tpriority ? tpriority.value : "";
      } else {
        event.ticketCategoryID = tpriority ? tpriority.value : "";
      }
    }
    if (type == "type") {
      let tpriority = e[e.length - 1];
      if (createTask.type == "task") {
        event.taskType = tpriority ? tpriority.value : "";
      } else {
        event.ticketType = tpriority ? tpriority.value : "";
      }
    }
    if (type == "company") {
      let tpriority = e[e.length - 1];
      event.company_id = tpriority ? tpriority.value : "";
      event.companyName = tpriority ? tpriority.label : "";
      event.projectID = "";
      event.project_name = "";
      event.location ="";
    }
    if (type == "project") {
      let tpriority = e[e.length - 1];
      event.projectID = tpriority ? tpriority.value : "";
      event.project_name = tpriority ? tpriority.label : "";
    }
    if (type == "priority") {
      let tpriority = e[e.length - 1];
      event.priority = tpriority ? tpriority.value : "";
    }
    if (type == "worktype") {
      let tworktype = e[e.length - 1];
      event.billingCodeID = tworktype ? tworktype.value : "";
    }
    if (type == "phase") {
      let tworktype = e[e.length - 1];
      event.phaseID = tworktype ? tworktype.value : "";
    }
    if (type == "status") {
      let tstatus = e[e.length - 1];
      event.status = tstatus ? tstatus.value : "";
      event.status_name = tstatus ? tstatus.label : "";
    }
    if (type == "issue") {
      let tissue = e[e.length - 1];
      event.issueType = tissue ? tissue.value : "";
      event.subIssueType = "";
    }
    if (type == "sub_issue") {
      let tsubissue = e[e.length - 1];
      event.subIssueType = tsubissue ? tsubissue.value : "";
    }
    if (type == "queue") {
      let tqueue = e[e.length - 1];
      event.queue = tqueue ? tqueue.value : "";
    }
    if (type == "source") {
      let tsource = e[e.length - 1];
      event.source = tsource ? tsource.value : "";
    }

    if (type == "description") {
      event.description = e.target.value;
    }
    if (type == "primary_resource") {
      let primary = e[e.length - 1];
      event.assignedResourceID = primary ? primary.resourceID : "";
      event.assignedResourceRoleID = primary ? primary.roleID : "";
      event.departmentID = primary ? primary.departmentID : "";
      event.primary = primary ? [primary] : [];
      if(event.assignedResourceID){
        event.secondary_resources = event.secondary_resources?.filter(
          (secondaryres: any) =>
            parseInt(secondaryres.resourceID) !==
            parseInt(event.assignedResourceID)
        );
      }
    }
    if (type == "secondary_resource") {
      let last_record = e[e.length - 1];
      let unique: any;
      if (last_record) {
        unique = e?.filter(
          (selectedresorce: any) =>
            parseInt(selectedresorce.resourceID) !==
            parseInt(last_record.resourceID)
        );
        unique.push(last_record);
      } else {
        unique = [];
      }
      event.secondary_resources = unique;
      if(event.assignedResourceID){
        event.secondary_resources = event.secondary_resources?.filter(
          (secondaryres: any) =>
            parseInt(secondaryres.resourceID) !==
            parseInt(event.assignedResourceID)
        );
      }
    }
    if((type == "secondary_resource" || type == "primary_resource") && !event.departmentID && event?.type ==="task"){
      event.secondary_resources?.filter((secondaryres: any) => {
        if(!event.departmentID){
          event = { ...event, departmentID: secondaryres?.departmentID };
        }
      });
    }
    if (event.start && event.end) {
      let duration = moment.duration(
        moment(event.end).diff(moment(event.start))
      );
      event.estimatedHours = duration.asHours();
    } else {
      event.estimatedHours = 0;
    }
    if (type == "location") {
      let LocationValue = e[e.length - 1];
      event.location = LocationValue ? LocationValue.value : "";
      event.isPrimary = LocationValue ? LocationValue.isPrimary : false;
    }
    setCreateTask(event);
    setshowCreateError(true);
    createpopupvalidation(event);
  };

  resource_settings?.data?.filter((el: any) => {
    if (el.task_roles && el.task_roles.length > 0) {
      // eslint-disable-next-line no-lone-blocks
      {
        el.task_roles?.map((et: any) => {
          let itemtask: any = {};
          var str = et.text;
          str = str?.replace(/ \([\s\S]*?\)/g, "");
          itemtask.resourceID = el.id;
          itemtask.label =
            el.firstName + " " + el?.lastName + " (" + et.text + ")";
          itemtask.departmentID = et.departmentID;
          itemtask.roleID = et.value;
          itemtask.value = parseInt(el.id.toString() + et.value.toString());
          itemtask.isActive = el.isActive;
          itemtask.isDisabled = !el.isActive;
          itemtask.has_license = el.has_license || el.resource_only;
          itemtask.database_id = el.database_id;
          itemtask.resource_only = el.resource_only;
          itemtask.userName = el.username;
          taskRoleArray.push(itemtask);
        });

        taskRoleArray.filter(function (item: any) {
          var i = tasksacendaryRoleArray.findIndex(
            (x: any) =>
              x.roleID == item.roleID && x.resourceID == item.resourceID
          );
          if (i <= -1) {
            tasksacendaryRoleArray.push(item);
          }
          return null;
        });
      }
    }
    if (el.ticket_roles && el.ticket_roles.length > 0) {
      // eslint-disable-next-line no-lone-blocks
      {
        el.ticket_roles?.map((eticket: any, eticketkey: any) => {
          let itemticket: any = {};
          itemticket.resourceID = el.id;
          itemticket.label =
            el.firstName + " " + el?.lastName + " (" + eticket.text + ")";
          itemticket.departmentID = eticket.departmentID;
          itemticket.roleID = eticket.value;
          itemticket.value = parseInt(
            el.id.toString() + eticket.value.toString()
          );
          itemticket.isActive = el.isActive;
          itemticket.isDisabled = !el.isActive;
          itemticket.has_license = el.has_license || el.resource_only;
          itemticket.userName = el.username;
          itemticket.database_id = el.database_id;
          itemticket.resource_only = el.resource_only;
          TicketRoleArray.push(itemticket);
        });
      }
    }
  });
  let companyData = isEditScreen ? companies : filterArray?.companies;

  const filtered = companyData?.map((el: any) => ({
    ...el,
    id: String(parseInt(el?.id) + 1),
    value: String(parseInt(el?.value) + 1),
  }));

  let projectDatas = isEditScreen ? projectList : filterArray?.projects;
  const filteredproj = projectDatas?.map((el: any) => ({
    ...el,
    companyID: String(parseInt(el?.companyID) + 1),
  }));

  let filteredPhase = phase
    ?.filter((el: any) => el.projectID == createTask.projectID)
    ?.map((el: any) => {
      return el;
    });

  const filteredLocation =Array.isArray(locations?.data) ?  locations?.data?.map((el: any) => ({
    ...el,
    companyID: String(parseInt(el?.companyID) + 1),
  })) : null;

  
  const handleHideOptionalFunc = (item: any, value: any) => {
    const options = !hideOptions
      ? item
      : item?.filter(
          (trole: any) =>
            parseInt(trole.value) === parseInt(value) || trole.isActive === true
        );

    return options;
  };

  useEffect(() => {
    if (!isOpen) {
      setshowCreateError(false);
      setCreateTask({
        type: createtype === "ticket" ? "ticket" : "task",
        title: "",
        company_id :companyId ? String(parseInt(companyId) + 1) : "",
        projectID: projectId ? projectId : "",
        phaseID: phaseId !== 0 ? phaseId : "",
        isDraft : isEditScreen ? true : false,
      });
      setIsValidCreateData(true);
    }
  }, [isOpen]);

  useEffect(() => {
    setshowCreateError(false);
    setCreateTask({
      ...createTask,
      type: createtype == "ticket" ? "ticket" : "task",
    });
    setIsValidCreateData(true);
  }, [createtype]);

  const popupCreateButtons = useMemo<any>(() => {
    return [
      "cancel",
      {
        handler: async function () {
          setIsFormDirty && setIsFormDirty(true);
          setIsCreateTaskOpen(false);
          setIsPopupOpen && setIsPopupOpen(false);
          let res: any;
          let createevent = {
            ...createTask,
            company_id: parseInt(createTask.company_id) - 1,
          };
          createevent.projectID = isEditScreen ? projectId : createTask.projectID;
          if (createTask.type === "task" && createevent) {
            res = await createtaskApi(createevent);
            if (res && res?.status === 200) {
              setIsRefatchTable && setIsRefatchTable(true);
              dispatch(setisCreated(true));
              if (screenName === 'planning') {
                toplanrefetch()
              }
            }
            setCreateTask({
              type: createtype === "ticket" ? "ticket" : "task",
              title: "",
              company_id :companyId ? String(parseInt(companyId) + 1) : "",
              projectID: projectId ? projectId : "",
              phaseID: phaseId !== 0 ? phaseId : "",
              isDraft : isEditScreen ? true : false,
            });
            setIsValidCreateData(true);
          } else if (createTask.type === "ticket" && createevent) {
            res = await createticketApi(createevent);
            if (res && res?.status === 200) {
              setIsRefatchTable && setIsRefatchTable(true);
              dispatch(setisCreated(true));
              if (screenName === 'planning') {
                toplanrefetch()
              }
              // refreshtodo(res?.data, "success", []);
            }
          }
        },
        cssClass: "mbsc-popup-button-primary",
        keyCode: "enter",
        disabled: isValidCreateData,
        text: "Create",
      },
    ];
  }, [isValidCreateData, createTask, general_settings]);

  const CustomOption = ({ data, ...props }: any) => {
    return <SelectCustomLockOption option={data} props={props} />;
  };

 const selectedQueue = filterArray?.queues?.data?.find((twork) => parseInt(twork.id) === parseInt(createTask?.queue));
  return (
    <>
      <Popup
        display="bottom"
        fullScreen={true}
        contentPadding={false}
        closeOnOverlayClick={false}
        buttons={popupCreateButtons}
        isOpen={isOpen}
        scrollLock={false}
        onClose={() => {
          setIsCreateTaskOpen(false);
          setIsPopupOpen && setIsPopupOpen(false);
        }}
        responsive={responsivePopup}
        cssClass="md-tooltip md-tooltip-detail plannPopup commonMidpopup assignPopup createPopup"
      >
        <div className="accordion" id="kt_accordion_1">
          {/* Related Tickets end */}
          <div className="fs-16 fontIner text-dark">
            <div className="min-h-65px"></div>
            <div
              className="md-tooltip-header d-flex justify-content-between px-7 position-absolute top-0 start-0 end-0 py-3 align-items-center min-h-65px"
              style={{
                backgroundColor: `${
                  createTask?.type == "task" ? "#FFE2E2" : "#DFDEFF"
                }`,
                zIndex: "1",
              }}
              id={`kt_accordion_1_header_task`}
            >
              <div
                className="position-absolute start-0 top-0 w-100 h-100 opacity-60"
                style={{
                  backgroundColor: `${
                    createTask?.type === "task" ? "#FFE2E2" : "#DFDEFF"
                  }`,
                  zIndex: "-1",
                }}
              ></div>
              <div className="d-flex align-items-center text-ellipsis">
                <a
                  target="_blank"
                  className="d-inline-flex align-items-center fw-bold text-primary fs-18 lh-1 align-left mw-100 pe-4"
                >
                  <span className="text-ellipsis lh-sm">
                    {general_settings?.show_task_type == true
                      ? createTask?.type?.charAt(0).toUpperCase() +
                        createTask?.type?.slice(1) +
                        ": " +
                        (createTask?.title ? createTask?.title : "")
                      : createTask?.title
                      ? createTask?.title
                      : ""}
                  </span>
                  <KTSVG
                    path="/media/icons/duotune/new-icons/right-arrow-new.svg"
                    className="m-0 ms-2"
                    svgClassName="w-auto h-auto"
                    stylecode={{ color: "inherit" }}
                  />
                </a>
              </div>

              <div className="d-flex align-items-center">
                {createTask?.status_name && (
                  <span
                    className="btn text-dark fw-normal rounded-pill px-3 py-0 min-h-24px fs-16 d-flex align-items-center text-nowrap me-7"
                    style={{ backgroundColor: "#EDF1F5" }}
                  >
                    {!(
                      createTask?.status_name == "Complete" ||
                      createTask?.status_name == "Complete"
                    ) && (
                      <small
                        className="min-w-6px min-h-6px rounded-circle me-8px"
                        style={{ backgroundColor: "#5195FC" }}
                      ></small>
                    )}
                    {(createTask?.status_name == "Complete" ||
                      createTask?.status_name == "complete") && (
                      <KTSVG
                        path="/media/icons/duotune/new-icons/check-icon.svg"
                        className="me-8px"
                        svgClassName="w-auto h-auto"
                      />
                    )}
                    {createTask?.status_name}
                  </span>
                )}
                <button
                  className="min-h-32px min-w-32px border-primary bg-white rounded-circle d-inline-flex align-items-center justify-content-center collapsed"
                  style={{ borderWidth: "1px" }}
                  data-bs-toggle="collapse"
                  data-bs-target={`#kt_accordion_1_body_task`}
                  aria-expanded="false"
                  aria-controls={`kt_accordion_1_body_task`}
                >
                  <KTSVG
                    path="/media/icons/duotune/new-icons/down-arrow-trans.svg"
                    className="d-inline-block m-0 text-primary valignTop"
                    svgClassName="w-12px h-auto"
                  />
                </button>
              </div>
            </div>
            <div
              className="paddLR24 md-tooltip-info position-relative accordion-collapse collapse show"
              id={`kt_accordion_1_body_task`}
              aria-labelledby={`kt_accordion_1_header_task`}
              data-bs-parent="#kt_accordion_1"
            >
              <div className="row row-cols-2 row-24">
                <div className="col col-12 mb-25px d-flex flex-column">
                  <strong className="fw-bold pb-1 required">Title: </strong>
                  <input
                    type="text"
                    className="form-control form-control-solid h-32px px-4 rounded-4px py-0 bg-white border-graybor fw-normal fs-16 text-dark"
                    value={createTask?.title}
                    data-id={createTask?.id}
                    data-type={createTask?.type}
                    onChange={(e: any) => changeCreatetaskValue(e, "title")}
                    required
                  />
                  {showCreateError && createTask?.title == "" && (
                    <span style={{ color: "red" }}>Please add title.</span>
                  )}
                  {createTask?.title != "" &&
                    createTask?.title?.length > 255 && (
                      <span style={{ color: "red" }}>
                        Title should not contain more than 255 characters.
                      </span>
                    )}
                </div>

                {createTask?.type == "task" && (
                  <>
                    <div className="col mb-4">
                      <div className="d-flex align-items-center">
                        <strong className="fw-bold required">
                          Start:&nbsp;
                        </strong>
                        <span className="align-items-center position-relative flex-fill creatDatepicker ps-1">
                          <Datepicker
                            controls={["calendar", "time"]}
                            touchUi={true}
                            buttons={[]}
                            display="anchored"
                            showOverlay={false}
                            onChange={(e: any) =>
                              changeCreatetaskValue(e, "start_date")
                            }
                            dateFormat="DD-MM-YYYY"
                            timeFormat={time_format}
                            max={
                              createTask?.end
                                ? moment(createTask?.end)
                                    .tz(timezone)
                                    .format("YYYY-MM-DDTHH:mm")
                                : ""
                            }
                            value={
                              createTask?.start
                                ? moment(createTask?.start)
                                    .tz(timezone)
                                    .format("YYYY-MM-DDTHH:mm")
                                : ""
                            }
                          />
                          <KTSVG
                            path="/media/icons/duotune/new-icons/general-drop-arrow.svg"
                            className="m-0 position-absolute end-0 top-0 bottom-0 pe-none w-21px d-inline-flex align-items-center"
                            svgClassName="w-12px h-auto"
                          />
                        </span>
                      </div>
                      {createTask?.type == "task" &&
                        showCreateError &&
                        (!createTask?.start || createTask?.start == "") && (
                          <>
                            <div className="w-100" style={{ color: "#f00" }}>
                              Please select start date.
                            </div>
                          </>
                        )}
                    </div>
                    <div className="col mb-4 d-flex flex-column ">
                      <div className="d-flex align-items-center">
                        <strong className="fw-bold required">End:&nbsp;</strong>
                        <span className="align-items-center position-relative flex-fill creatDatepicker ps-1">
                          <Datepicker
                            controls={["calendar", "time"]}
                            touchUi={true}
                            display="anchored"
                            showOverlay={false}
                            onChange={(e: any) =>
                              changeCreatetaskValue(e, "end_date")
                            }
                            dateFormat="DD-MM-YYYY"
                            timeFormat={time_format}
                            buttons={[]}
                            min={
                              createTask?.start
                                ? moment(createTask?.start)
                                    .tz(timezone)
                                    .format("YYYY-MM-DDTHH:mm")
                                : ""
                            }
                            value={
                              createTask?.end
                                ? moment(createTask?.end)
                                    .tz(timezone)
                                    .format("YYYY-MM-DDTHH:mm")
                                : ""
                            }
                          />
                          <KTSVG
                            path="/media/icons/duotune/new-icons/general-drop-arrow.svg"
                            className="m-0 position-absolute end-0 top-0 bottom-0 pe-none w-21px d-inline-flex align-items-center"
                            svgClassName="w-12px h-auto"
                          />
                        </span>
                      </div>
                      {createTask?.type == "task" &&
                        showCreateError &&
                        (!createTask?.end || createTask?.end == "") && (
                          <>
                            <span style={{ color: "red" }}>
                              Please select end date.
                            </span>
                          </>
                        )}
                    </div>
                    <div className="col mb-20px flex-column">
                      <strong className="fw-bold required">
                        Category:&nbsp;
                      </strong>
                      {createTask?.type == "task" && taskCategory?.data && (
                        <Select
                          value={taskCategory?.data?.filter(
                            (twork: any) =>
                              parseInt(twork.value) ==
                              parseInt(createTask?.taskCategoryID)
                          )}
                          isMulti
                          isClearable={false}
                          name="Issues"
                          placeholder="Select category"
                          options={handleHideOptionalFunc(
                            taskCategory?.data,
                            createTask?.taskCategoryID
                          )}
                          className="basic-multi-select"
                          classNamePrefix="select"
                          isOptionDisabled={(option: any) => !option.isActive}
                          onChange={(e: any) =>
                            changeCreatetaskValue(e, "category")
                          }
                        />
                      )}
                      {/*{createTask?.type == "ticket" && filterArray?.ticketCategory?.data &&
                                                <Select
                                                    value={filterArray?.ticketCategory?.data?.filter((ticketwork: any) => ((((parseInt(ticketwork.value) == parseInt(createTask?.ticketCategoryID))))))}
                                                    isMulti
                                                    isClearable={false}
                                                    name="Issues"
                                                    placeholder="Select category"
                                                    options={filterArray?.ticketCategory?.data}
                                                    className="basic-multi-select"
                                                    classNamePrefix="select"
                                                    onChange={(e:any) => changeCreatetaskValue(e,'category')}
                                                    />
                                            } */}
                      {createTask?.type == "task" &&
                        showCreateError &&
                        (!createTask?.taskCategoryID ||
                          createTask?.taskCategoryID == "") && (
                          <>
                            <span style={{ color: "red" }}>
                              Please select category.
                            </span>
                          </>
                        )}
                      {createTask?.type == "ticket" &&
                        showCreateError &&
                        (!createTask?.ticketCategoryID ||
                          createTask?.ticketCategoryID == "") && (
                          <>
                            <span style={{ color: "red" }}>
                              Please select category.
                            </span>
                          </>
                        )}
                    </div>
                    <div className="col mb-20px flex-column">
                      <strong className="fw-bold required">Type:&nbsp;</strong>
                      {createTask?.type == "task" && taskType?.data && (
                        <Select
                          value={taskType?.data?.filter(
                            (twork: any) =>
                              parseInt(twork.value) ==
                              parseInt(createTask?.taskType)
                          )}
                          isMulti
                          isClearable={false}
                          name="Issues"
                          placeholder="Select type"
                          options={handleHideOptionalFunc(
                            taskType?.data,
                            createTask?.taskType
                          )}
                          className="basic-multi-select"
                          classNamePrefix="select"
                          isOptionDisabled={(option: any) => !option.isActive}
                          onChange={(e: any) =>
                            changeCreatetaskValue(e, "type")
                          }
                        />
                      )}
                      {createTask?.type == "task" &&
                        showCreateError &&
                        (!createTask?.taskType ||
                          createTask?.taskType == "") && (
                          <>
                            <span style={{ color: "red" }}>
                              Please select type.
                            </span>
                          </>
                        )}
                      {createTask?.type == "ticket" &&
                        showCreateError &&
                        (!createTask?.ticketType ||
                          createTask?.ticketType == "") && (
                          <>
                            <span style={{ color: "red" }}>
                              Please select type.
                            </span>
                          </>
                        )}
                    </div>
                  </>
                )}

                <div className="col mb-20px flex-column">
                  <strong className="fw-bold required">Company:&nbsp;</strong>
                  {filtered && (
                    <Select
                      value={filtered?.filter(
                        (twork: any) => twork.value == createTask?.company_id
                      )}
                      isMulti
                      isClearable={false}
                      name="Company"
                      // isDisabled={createTask?.type == "task" ? true : false}
                      placeholder="Select company"
                      options={handleHideOptionalFunc(
                        filtered,
                        createTask?.company_id
                      )}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      isOptionDisabled={(option: any) => !option.isActive}
                      onChange={(e: any) => changeCreatetaskValue(e, "company")}
                      isDisabled={isEditScreen}
                    />
                  )}
                  {showCreateError &&
                    (!createTask?.company_id ||
                      createTask?.company_id == "") && (
                      <>
                        <span style={{ color: "red" }}>
                          Please select company.
                        </span>
                      </>
                    )}
                </div>

                <div className="col mb-20px flex-column">
                  <strong
                    className={
                      createTask?.type == "task"
                        ? `fw-bold required`
                        : `fw-bold`
                    }
                  >
                    Project:&nbsp;
                  </strong>
                  {filteredproj && (
                    <Select
                      value={filteredproj?.filter(
                        (twork: any) => twork.value === createTask?.projectID
                      )}
                      isMulti
                      isClearable={false}
                      name="Project"
                      placeholder="Select project"
                      options={filteredproj?.filter((twork: any) =>
                        twork.companyID === createTask?.company_id
                          ? createTask?.company_id
                          : ""
                      )}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      isOptionDisabled={(option: any) => !option.isActive}
                      onChange={(e: any) => changeCreatetaskValue(e, "project")}
                      isDisabled={isEditScreen}
                    />
                  )}
                  {createTask?.type == "task" &&
                    showCreateError &&
                    (!createTask?.projectID || createTask?.projectID == "") && (
                      <>
                        <span style={{ color: "red" }}>
                          Please select project.
                        </span>
                      </>
                    )}
                </div>

                <div className="col mb-20px flex-column">
                  <strong className="fw-bold required">Priority:&nbsp;</strong>
                  {prioritylist && (
                    <Select
                      value={prioritylist?.filter(
                        (twork: any) =>
                          parseInt(twork.value) ==
                          parseInt(createTask?.priority)
                      )}
                      isMulti
                      isDisabled={createTask?.type == "task" ? true : false}
                      isClearable={false}
                      name="Issues"
                      placeholder="Select priority"
                      options={handleHideOptionalFunc(
                        prioritylist,
                        createTask?.priority
                      )}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      isOptionDisabled={(option: any) => !option.isActive}
                      onChange={(e: any) =>
                        changeCreatetaskValue(e, "priority")
                      }
                    />
                  )}
                  {createTask?.type == "ticket" &&
                    showCreateError &&
                    (!createTask?.priority || createTask?.priority == "") && (
                      <>
                        <span style={{ color: "red" }}>
                          Please select priority.
                        </span>
                      </>
                    )}
                </div>

                <div className="col mb-20px">
                  <strong className="fw-bold">Primary:</strong>
                  <div className="position-relative">
                    <KTSVG
                      path="/media/icons/duotune/new-icons/primary-user.svg"
                      className="m-0 position-absolute top-50 translate-middle-y pe-none ms-2"
                      svgClassName="w-18px h-auto"
                    />
                    {TicketRoleArray && taskRoleArray && (
                      <>
                        {createTask?.type == "ticket" && (
                          <Select
                            value={TicketRoleArray?.filter(
                              (trole: any) =>
                                parseInt(trole.resourceID) ==
                                  parseInt(createTask?.assignedResourceID) &&
                                parseInt(trole.roleID) ==
                                  parseInt(createTask?.assignedResourceRoleID)
                            )}
                            isMulti
                            isClearable={false}
                            placeholder="Select primary resource"
                            name="Primary Resource"
                            options={
                              hideOptions
                                ? TicketRoleArray?.filter(
                                    (trole: any) =>
                                      (parseInt(trole.resourceID) ==
                                        parseInt(
                                          createTask.assignedResourceID
                                        ) &&
                                        parseInt(trole.roleID) ==
                                          parseInt(
                                            createTask.assignedResourceRoleID
                                          )) ||
                                      trole.isDisabled === false
                                  )
                                : TicketRoleArray
                            }
                            components={{ Option: CustomOption }}
                            formatOptionLabel={formatOptionLabel}
                            className="basic-multi-select"
                            classNamePrefix="select"
                            isOptionDisabled={(option: any) =>
                              option.isDisabled || !option.has_license
                            }
                            // isOptionDisabled={(option:any) => !option.isActive}
                            onChange={(e: any) =>
                              changeCreatetaskValue(e, "primary_resource")
                            }
                          />
                        )}
                        {createTask?.type == "task" && (
                          <Select
                            value={taskRoleArray?.filter(
                              (trole: any) =>
                                parseInt(trole.resourceID) ==
                                  parseInt(createTask?.assignedResourceID) &&
                                parseInt(trole.roleID) ==
                                  parseInt(
                                    createTask?.assignedResourceRoleID
                                  ) &&
                                parseInt(trole.departmentID) ==
                                  parseInt(createTask?.departmentID)
                            )}
                            isMulti
                            isClearable={false}
                            placeholder="Select primary resource"
                            name="Primary Resource"
                            options={
                              hideOptions
                                ? taskRoleArray?.filter(
                                    (trole: any) =>
                                      (parseInt(trole.resourceID) ==
                                        parseInt(
                                          createTask?.assignedResourceID
                                        ) &&
                                        parseInt(trole.roleID) ==
                                          parseInt(
                                            createTask?.assignedResourceRoleID
                                          ) &&
                                        parseInt(trole.departmentID) ==
                                          parseInt(createTask?.departmentID)) ||
                                      trole.isDisabled === false
                                  )
                                : taskRoleArray
                            }
                            components={{ Option: CustomOption }}
                            formatOptionLabel={formatOptionLabel}
                            className="basic-multi-select"
                          classNamePrefix="select"
                            isOptionDisabled={(option: any) =>
                              option.isDisabled || !option.has_license
                            }
                            // isOptionDisabled={(option:any) => !option.isActive}
                            onChange={(e: any) =>
                              changeCreatetaskValue(e, "primary_resource")
                            }
                          />
                        )}
                      </>
                    )}
                  </div>
                </div>

                <div className="col mb-20px">
                  <strong className="fw-bold">Secondary Resource(s): </strong>
                  <div className="position-relative">
                    <div className="dropDown position-relative" style={{}}>
                      {createTask?.type == "task" && (
                        <Select
                          value={tasksacendaryRoleArray.filter((e: any) =>
                            createTask?.secondary_resources
                              ?.map(
                                (axisavailability: any) =>
                                  axisavailability.value
                              )
                              .includes(e.value)
                          )}
                          isMulti
                          name="Secondary Resource"
                          placeholder="Select secondary resource"
                          options={
                            hideOptions
                              ? tasksacendaryRoleArray?.filter(
                                  (twork: any) =>
                                    parseInt(twork.resourceID) !=
                                      parseInt(
                                        createTask?.assignedResourceID
                                      ) && twork.isDisabled === false
                                )
                              : tasksacendaryRoleArray?.filter(
                                  (twork: any) =>
                                    parseInt(twork.resourceID) !==
                                    parseInt(createTask?.assignedResourceID)
                                )
                          }
                          components={{ Option: CustomOption }}
                          formatOptionLabel={formatOptionLabel}
                          className="basic-multi-select"
                          classNamePrefix="select"
                          isOptionDisabled={(option: any) =>
                            option.isDisabled || !option.has_license
                          }
                          // isOptionDisabled={(option:any) => !option.isActive}
                          onChange={(e: any) =>
                            changeCreatetaskValue(e, "secondary_resource")
                          }
                        />
                      )}
                      {createTask?.type == "ticket" && (
                        <Select
                          value={TicketRoleArray.filter((e: any) =>
                            createTask.secondary_resources
                              ?.map(
                                (axisavailability: any) =>
                                  axisavailability.value
                              )
                              .includes(e.value)
                          )}
                          isMulti
                          name="Secondary Resource"
                          placeholder="Select secondary resource"
                          options={
                            hideOptions
                              ? TicketRoleArray?.filter(
                                  (twork: any) =>
                                    parseInt(twork.resourceID) !=
                                      parseInt(
                                        createTask?.assignedResourceID
                                      ) && twork.isDisabled === false
                                )
                              : TicketRoleArray?.filter(
                                  (twork: any) =>
                                    parseInt(twork.resourceID) !=
                                    parseInt(createTask?.assignedResourceID)
                                )
                          }
                          components={{ Option: CustomOption }}
                          formatOptionLabel={formatOptionLabel}
                          className="basic-multi-select"
                          classNamePrefix="select"
                          isOptionDisabled={(option: any) =>
                            option.isDisabled || !option.has_license
                          }
                          // isOptionDisabled={(option:any) => !option.isActive}
                          onChange={(e: any) =>
                            changeCreatetaskValue(e, "secondary_resource")
                          }
                        />
                      )}
                    </div>
                  </div>
                </div>

                <div className="col mb-20px">
                  <strong className="fw-bold required">Worktype:</strong>
                  {worktype && (
                    <Select
                      value={worktype?.filter(
                        (trole: any) =>
                          parseInt(trole.value) ==
                          parseInt(createTask?.billingCodeID)
                      )}
                      isMulti
                      isClearable={false}
                      placeholder="Select work type"
                      name="Work Type"
                      options={handleHideOptionalFunc(
                        worktype,
                        createTask?.billingCodeID
                      )}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      isOptionDisabled={(option: any) => !option.isActive}
                      onChange={(e: any) =>
                        changeCreatetaskValue(e, "worktype")
                      }
                    />
                  )}
                  {showCreateError &&
                    (!createTask?.billingCodeID ||
                      createTask?.billingCodeID == "") && (
                      <>
                        <span style={{ color: "red" }}>
                          Please select work type.
                        </span>
                      </>
                    )}
                  {worktype?.filter(
                    (trole: any) =>
                      parseInt(trole.value) ==
                        parseInt(createTask?.billingCodeID) &&
                      trole.isActive == false
                  )?.length != 0 && (
                    <>
                      <span style={{ color: "red" }}>
                        Selected worktype is inactive from autotask.
                      </span>
                    </>
                  )}
                </div>

                <div className="col mb-20px">
                  <strong className="fw-bold required">Status:</strong>
                  {task_status && (
                    <Select
                      value={task_status?.filter(
                        (twork: any) =>
                          parseInt(twork.value) == parseInt(createTask?.status)
                      )}
                      isMulti
                      isClearable={false}
                      placeholder="Select status"
                      name="Status"
                      options={handleHideOptionalFunc(
                        task_status,
                        createTask?.status
                      )}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      isOptionDisabled={(option: any) => !option.isActive}
                      onChange={(e: any) => changeCreatetaskValue(e, "status")}
                    />
                  )}
                  {showCreateError &&
                    (!createTask?.status || createTask?.status == "") && (
                      <>
                        <span style={{ color: "red" }}>
                          Please select status.
                        </span>
                      </>
                    )}
                  {task_status && task_status?.filter(
                    (twork: any) =>
                      parseInt(twork.value) == parseInt(createTask?.status) &&
                      twork.isActive == false
                  )?.length != 0 && (
                    <>
                      <span style={{ color: "red" }}>
                        Selected status is inactive from autotask.
                      </span>
                    </>
                  )}
                </div>

                {createTask?.type == "task" && (
                  <div className="col mb-20px">
                    <strong className="fw-bold">Phase:</strong>
                    {filteredPhase && (
                      <Select
                        value={filteredPhase?.filter(
                          (tphase: any) =>
                            parseInt(tphase.value) ===
                            parseInt(createTask?.phaseID)
                        )}
                        isMulti
                        isClearable={false}
                        placeholder="Select phase"
                        name="Phase"
                        options={filteredPhase?.filter((twork: any) =>
                          parseInt(twork.projectID) ===
                          parseInt(createTask?.projectID)
                            ? parseInt(createTask?.projectID)
                            : ""
                        )}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        isOptionDisabled={(option: any) =>
                          option?.isActive == false ? true : false
                        }
                        onChange={(e: any) => changeCreatetaskValue(e, "phase")}
                      />
                    )}
                  </div>
                )}

                <div className="col mb-20px flex-column">
                  <strong className="fw-bold required">Issue Type:</strong>
                  {issueslist && (
                    <Select
                      value={issueslist?.filter(
                        (twork: any) =>
                          parseInt(twork.value) ==
                          parseInt(createTask?.issueType)
                      )}
                      isMulti
                      isDisabled={createTask?.type == "task" ? true : false}
                      isClearable={false}
                      name="Issues"
                      options={handleHideOptionalFunc(
                        issueslist,
                        createTask?.issueType
                      )}
                      placeholder="Select issue type"
                      className="basic-multi-select"
                      classNamePrefix="select"
                      isOptionDisabled={(option: any) => !option.isActive}
                      onChange={(e: any) => changeCreatetaskValue(e, "issue")}
                    />
                  )}
                  {createTask?.type == "ticket" &&
                    showCreateError &&
                    (!createTask?.issueType || createTask?.issueType == "") && (
                      <>
                        <span style={{ color: "red" }}>
                          Please select issue type.
                        </span>
                      </>
                    )}
                </div>

                <div className="col mb-20px">
                  <strong className="fw-bold required">Sub Issue Types:</strong>
                  {subIssueslist && (
                    <Select
                      value={subIssueslist?.filter(
                        (twork: any) =>
                          parseInt(twork.value) ==
                          parseInt(createTask?.subIssueType)
                      )}
                      isMulti
                      isDisabled={createTask?.type == "task" ? true : false}
                      isClearable={false}
                      placeholder="Select sub-issue type"
                      name="Issues"
                      options={
                        hideOptions
                          ? subIssueslist?.filter(
                              (twork: any) =>
                                (parseInt(twork.parentIssue) ==
                                  parseInt(createTask?.issueType) ||
                                  twork.parentIssue == "") &&
                                twork.isActive === true
                            )
                          : subIssueslist?.filter(
                              (twork: any) =>
                                parseInt(twork.parentIssue) ==
                                  parseInt(createTask?.issueType) ||
                                twork.parentIssue == ""
                            )
                      }
                      className="basic-multi-select"
                      classNamePrefix="select"
                      isOptionDisabled={(option: any) => !option.isActive}
                      onChange={(e: any) =>
                        changeCreatetaskValue(e, "sub_issue")
                      }
                    />
                  )}
                  {createTask?.type == "ticket" &&
                    showCreateError &&
                    (!createTask?.subIssueType ||
                      createTask?.subIssueType == "") && (
                      <>
                        <span style={{ color: "red" }}>
                          Please select sub issue type.
                        </span>
                      </>
                    )}
                </div>

                {/* location feild  */}
                {locationShow && (
                  <div className="col mb-20px flex-column">
                    <strong className="fw-bold ">
                      Location:&nbsp;
                    </strong>
                      <Select
                        value={locations?.data ? locations?.data
                          ?.filter(
                            (twork: any) =>
                              parseInt(twork.autotask_id) ==
                              parseInt(createTask?.location)
                          )
                          .map((option) => ({
                            label: option.name,
                            value: option.autotask_id,
                          })):null}
                        isMulti
                        isClearable={false}
                        name="Location"
                        placeholder="Select Location"
                        options={filteredLocation
                          ?.filter((twork: any) =>
                            twork.companyID == createTask?.company_id
                              ? createTask?.company_id
                              : ""
                          )
                          .filter((twork: any) => !hideOptions || twork.isActive) 
                          .map((option) => ({
                            label: option.name,
                            value: option.autotask_id,
                            isPrimary: option.isPrimary,
                            isActive: option.isActive,
                          }))}

                          isOptionDisabled={(option: any) =>
                            !option.isActive
                          }
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={(e: any) =>
                          changeCreatetaskValue(e, "location")
                        }
                      />
                  </div>
                )}
                {/* Addition fields for add ticket */}
                {/* Queue for ticket */}
                {createTask?.type == 'ticket' && 
                                    <div className="col mb-20px">
                                        <strong className='fw-bold'>Queue:</strong>                                          
                                        {filterArray?.queues &&
                                            <Select
                                            value={selectedQueue ? { value: selectedQueue.id, label: selectedQueue.name } : null}
                                            isMulti
                                            isClearable={false}
                                            placeholder="Select Queue"
                                            name="Queue"
                                            options={filterArray?.queues?.data
                                              .filter((twork: any) => !hideOptions || twork.isActive).map(item => ({label: item.name, value: item.id , isActive: item.isActive} ))}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            isOptionDisabled={(option: any) =>
                                              !option.isActive
                                            }
                                            onChange={(e:any) => changeCreatetaskValue(e,'queue')}
                                            /> 
                                        }
                                           { selectedQueue && filterArray?.queues?.data?.filter(
                                              (twork: any) =>
                                                parseInt(twork.id) ===
                                                  parseInt(selectedQueue.id) &&
                                                twork.isActive === false
                                            )?.length !== 0 && (
                                              <>
                                                <span style={{ color: "red" }}>
                                                  Selected Queue is inactive from autotask.
                                                </span>
                                              </>
                                            )}  
                                    </div>
                                    }
                {/* Queue for ticket :: END */}
              
                <div className="col col-12 mb-4">
                  <strong className="fw-bold pe-8px">First response:</strong>
                  <span className="fw-bold text-primary me-15">
                    {createTask?.firstResponseDateTime
                      ? moment(createTask?.firstResponseDateTime)
                          .tz(timezone)
                          .format("ddd DD-MM-YYYY")
                      : ""}{" "}
                    {createTask?.firstResponseDateTime
                      ? moment(createTask?.firstResponseDateTime)
                          .tz(timezone)
                          .format(time_format)
                      : "-"}
                    <KTSVG
                      path="/media/icons/duotune/new-icons/datepicker.svg"
                      className="d-inline-block m-0 ms-2 text-dark d-none"
                      svgClassName="w-20px h-auto"
                    />
                  </span>
                  <strong className="fw-bold pe-8px">Due date:</strong>
                  <span className="fw-bold text-primary">
                    {createTask?.endDateTime
                      ? moment(createTask?.endDateTime)
                          .tz(timezone)
                          .format("ddd DD-MM-YYYY")
                      : ""}{" "}
                    {createTask?.endDateTime
                      ? moment(createTask?.endDateTime)
                          .tz(timezone)
                          .format(time_format)
                      : "-"}
                    <KTSVG
                      path="/media/icons/duotune/new-icons/datepicker.svg"
                      className="d-inline-block m-0 ms-2 text-dark d-none"
                      svgClassName="w-20px h-auto"
                    />
                  </span>
                </div>

                <div className="col col-12 flex-column">
                  <strong className="fw-bold">Description:</strong>
                  <span>
                    <textarea
                      placeholder="Ticket / Task Description"
                      className="form-control form-control-solid h-55px bg-white border-graybor rounded-4px fw-normal fs-16 text-dark resize-none px-4 py-2"
                      rows={3}
                      name="popuptaskdescription"
                      id="popuptaskdescription"
                      data-id={createTask?.id}
                      data-type={createTask?.type}
                      value={createTask?.description}
                      onChange={(e: any) =>
                        changeCreatetaskValue(e, "description")
                      }
                    >
                      {createTask?.description}
                    </textarea>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Popup>
    </>
  );
};

export default TaskCreate;
