/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import clsx from "clsx";
import { useLayout } from "../../core";
import { KTSVG, toAbsoluteUrl, toApiAbsoluteUrl } from "../../../helpers";
import { AsideMenu } from "./AsideMenu";
import { useAuth } from "../../../../app/modules/auth/core/AuthCognito";
import { AsideMenuItem } from "./AsideMenuItem";
import { AsideMenuItemWithSub } from "./AsideMenuItemWithSub";
import { confirm } from "@mobiscroll/react";
import { setisSaved } from "../../../../app/counterSlice";
import { useAppDispatch, useAppSelector } from "../../../../app/hook";
import { OverlayTrigger, Popover } from "react-bootstrap";

const AsideDefault: FC = () => {
  const { currentUser, logout } = useAuth();
  const { config, classes } = useLayout();
  const asideRef = useRef<HTMLDivElement | null>(null);
  const { aside } = config;
  const toolbarUserAvatarHeightClass = "symbol-30px symbol-md-38px";
  const scrollRef = useRef<HTMLDivElement | null>(null);

  const blankImg = toAbsoluteUrl("/media/svg/avatars/blank.svg");

  const minimize = () => {
    asideRef.current?.classList.add("animating");
    setTimeout(() => {
      asideRef.current?.classList.remove("animating");
    }, 300);
  };

  useEffect(() => {
    setTimeout(() => {
      if (scrollRef.current) {
        scrollRef.current.scrollTop = 0;
      }
    }, 50);
  }, []);

  const is_Changed = useAppSelector((state: any) => state.counter?.isSaved);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const handleClick = (event: any) => {
    if(is_Changed == 'true' || is_Changed == true){
      event.preventDefault();
        confirm({
          title: 'Changes you made may not be saved. Are you sure you want to leave the page?',
          okText: 'Yes',
          cancelText: 'No',
          callback: function (result) {
              if(result){
                if(currentUser?.data.type === 'superadmin'){
                  navigate("dashboard");
                }
                else{
                  navigate("/apps/planning/resources");
                }
                dispatch(setisSaved(false));
              }
          }
        });
    }
    else{
      // navigate({currentUser?.data.type === 'superadmin' ? "dashboard" : "/apps/planning/resources"});
      if(currentUser?.data.type === 'superadmin'){
        navigate("dashboard");
      }
      else{
        navigate("/apps/planning/resources");
      }
    }
  }

  const logoutClick = (event: any) => {
    if(is_Changed == 'true' || is_Changed == true){
      event.preventDefault();
        confirm({
          title: 'Changes you made may not be saved. Are you sure you want to leave the page?',
          okText: 'Yes',
          cancelText: 'No',
          callback: function (result) {
              if(result){
                logout()
                dispatch(setisSaved(false));
              }
          }
        });
    }
    else{
      logout()
    }
  }

  const renderCompanyLogoTooltip = (
    <Popover id="renderAddUserTooltip">
      <div className="p-5 bg-white card">
        <div>
          <a className="text-decoration-underline"
            href="https://knowledgebase.proxuma.io/"
            target="_blank"
          >
            Learn more about proxuma!
          </a>
        </div>
      </div>
    </Popover>
  )
  const renderProfilePageTooltip = (
    <Popover id="renderProfilePageTooltip">
      <div className="p-5 bg-white card">
        <div>
            You can access the tours here if you want to redo them.
        </div>
      </div>
    </Popover>
  )
  return (
    <div
      id="kt_aside"
      className={clsx("aside bg-primary", classes.aside.join(" "))}
      data-kt-drawer="true"
      data-kt-drawer-name="aside"
      data-kt-drawer-activate="{default: true, xl: false}"
      data-kt-drawer-overlay="true"
      data-kt-drawer-width="{default:'210px'}"
      data-kt-drawer-direction="start"
      data-kt-drawer-toggle="#kt_aside_mobile_toggle"
      ref={asideRef}
    >
      {/* begin::Brand */}
      <div className="aside-logo flex-column-auto mb-12" id="kt_aside_logo">
        {/* begin::Logo */}
        {aside.theme === "dark" && (
          // <Link style={{ color: "white" }}
          // to={currentUser?.data.type === 'superadmin' ? "dashboard" : "/apps/planning/resources"}
          // className="d-flex align-content-center justify-content-center flex-wrap w-100 h-100 p-1"
          // >
          <a style={{ color: "white" }}
          // to={currentUser?.data.type === 'superadmin' ? "dashboard" : "/apps/planning/resources"}
          onClick={handleClick}
          className="d-flex align-items-center justify-content-center flex-wrap w-100 h-100 p-1">
            {/* <img
      alt='Proxuma'
      className='h-auto logo mw-100 mh-100'
      src={toAbsoluteUrl('/media/logos/logo.png')}
    /> */}

             {currentUser?.data.type == 'superadmin' && currentUser?.data?.setting?.company_logo ?
              <img
              alt='Proxuma'
              className='h-auto logo mw-100 mh-100 w-130px'
              src={(`${currentUser?.data?.setting?.company_logo}`)}
            />
            :
              <>
                <img
                  alt='Proxuma'
                  className='h-auto logo mw-100 mh-100 w-130px'
                  src={toAbsoluteUrl('/media/logos/horizontal-dark-bg.svg')} />
              </>
            }
            </a>
          // </Link>
        )}
        { currentUser?.data.type == 'superadmin' && currentUser?.data?.setting?.company_logo ? '' : 
          <div className="h-32px d-flex align-items-center" style={{ transform: 'translateX(-40px)' }}>
          <OverlayTrigger
            trigger="click"
            rootClose
            placement="bottom-end"
            overlay={renderCompanyLogoTooltip}
          >
            <button className="btn p-0 text-center rounded-3px border lh-1 border-white text-white fs-6 w-10px color-white infoBtn" type="button">i</button>
          </OverlayTrigger>
        </div>
        }
        {aside.theme === "light" && (
          <Link to="/apps/dashboard-management/dashboard">
             {currentUser?.data.type == 'superadmin' ?
             <img
             alt='Proxuma'
             className='h-auto logo mw-100 mh-100'
             src={toApiAbsoluteUrl(`${currentUser?.data?.setting?.company_logo}`)}
           />
           :
            <img
              alt="Logo"
              className="h-25px logo"
              src={toAbsoluteUrl("/media/logos/default.svg")}
            />
        }
          </Link>
        )}
        {/* end::Logo */}

        {/* begin::Aside toggler */}
        {/* {aside.minimize && (
          <div
            id="kt_aside_toggle"
            className="btn btn-icon w-auto px-0 btn-active-color-primary aside-toggle"
            data-kt-toggle="true"
            data-kt-toggle-state="active"
            data-kt-toggle-target="body"
            data-kt-toggle-name="aside-minimize"
            onClick={minimize}
          >
            <KTSVG
              path={"/media/icons/duotune/arrows/arr080.svg"}
              className={"svg-icon-1 rotate-180"}
            />
          </div>
        )} */}
        {/* end::Aside toggler */}
      </div>
      {/* end::Brand */}

      {/* begin::Aside menu */}
      <div className="aside-menu flex-column-fluid">
        <AsideMenu asideMenuCSSClasses={classes.asideMenu} />
      </div>
      {/* end::Aside menu */}

      {/* begin::Footer */}
      <div className='aside-footer d-flex flex-column aside-menu' id='kt_aside_footer'>
        {/* <div>
          <a
            target='_blank'
            className=''
            // href='#'
            data-bs-toggle='tooltip'
            data-bs-trigger='hover'
            data-bs-dismiss-='click'
            title='Check out the complete documentation with over 100 components'
          >
            <KTSVG path='/media/icons/duotune/art/setting.svg' className="" svgClassName="w-18px h-auto" />
            <span className='menu-title'>Settings</span>
          </a>
      </div>   */}
      <div
      id="kt_aside_menu_wrapper"
      ref={scrollRef}
      className="hover-scroll-overlay-y"
      data-kt-scroll="true"
      data-kt-scroll-activate="{default: false, lg: false}"
      data-kt-scroll-height="auto"
      data-kt-scroll-dependencies="#kt_aside_logo, #kt_aside_footer"
      data-kt-scroll-wrappers="#kt_aside_menu"
      data-kt-scroll-offset="0"
    >
      
      <div
        id="#kt_aside_menu"
        data-kt-menu="true"
        className={clsx(
          "menu menu-column menu-title-gray-800 menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-500",
        )}
      >
      <div className="position-relative">
        {
          currentUser?.data.type !== 'superadmin' &&
          <div className="h-40px d-flex align-items-center position-absolute end-0 top-0 pe-2" >
          <OverlayTrigger
            trigger="click"
            rootClose
            placement="bottom-end"
            overlay={renderProfilePageTooltip}
            >
            <button className="btn p-0 text-center rounded-3px border lh-1 border-white text-white fs-6 w-10px color-white infoBtn" type="button">i</button>
          </OverlayTrigger>
        </div>
          }
        <AsideMenuItemWithSub
          to='/apps/profile'
          title={
            currentUser?.data.type === 'superadmin' ?
              currentUser?.data.username :
              currentUser?.data?.user?.username
          }
          className="flex-fill"
          fontIcon='bi-layers'
          icon=''
          imgsrc=
          {
            (currentUser?.data.type === 'superadmin' && currentUser?.data?.avatar) ? (`${currentUser?.data?.avatar}`) : (currentUser?.data?.user?.avatar ?
            (`${currentUser?.data?.user?.avatar}`) : blankImg)
          }
        >
          <AsideMenuItem to={currentUser?.data.type === 'superadmin' ? '/superadmin/apps/profile' : '/apps/profile'} title='My profile' hasBullet={true} />

        </AsideMenuItemWithSub>
        </div>
      </div>
      </div>
        <div>
          <a
            target='_blank'
            className=''
            onClick={logoutClick}
            data-bs-toggle='tooltip'
            data-bs-trigger='hover'
            data-bs-dismiss-='click'
          >
            <KTSVG path='/media/icons/duotune/art/logout.svg' className="" svgClassName="w-20px h-auto" />
            <span className='menu-title'>Logout</span>
          </a>
        </div>
      </div>
      {/* end::Footer */}
    </div>
  );
};

export { AsideDefault };
