import { formatDate, MbscCalendarEvent, Page } from "@mobiscroll/react";
import "@mobiscroll/react/dist/css/mobiscroll.min.css";
import moment from "moment-timezone";
import {
  FC,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import "react-notifications/lib/notifications.css";
import { useNavigate } from "react-router-dom";
import "../../../../_metronic/assets/sass/project.scss";
import {
  createPopupvalues,
  getProjectTask,
  getServicecallDetail,
  getTaskDetail,
  getTaskDetailCapsule,
  updatePopupvalues,
  updateTasks,
} from "../../../../_metronic/requests/PlanningRequest";
import ServicecallUpdate from "../../../components/modals/servicecallUpdate";
import TaskUpdate from "../../../components/modals/taskUpdate";
import { webSocketContext } from "../../../context/WebSocketContext";
import {
  checkBufferHours,
  resourcehasNoLicenseMoreMultiple,
  splitServicecallEvents,
  splitedEventCall,
} from "../../../helpers/servicecall";
import { setOptions } from "../../../mobiscroll/core/commons";
import { toast } from "../../../mobiscroll/core/components/notifications/notifications.common";
import { momentTimezone } from "../../../mobiscroll/core/util/moment";
import {
  Eventcalendar,
  MbscEventcalendarView,
} from "../../../mobiscroll/react/components/eventcalendar";
import { useAuth } from "../../auth/core/AuthCognito";
import FilterMain from "../filters/ProjectFilter/FilterMain";
import "./App.css";
import PhaseCreate from "./PhaseCreate";
import TaskCreate from "./TaskCreate";
import CreateButton from "./components/CreateButton";
import CustomWithNavButtons from "./components/CustomWithNavButtons";
import MyScheduleEvent from "./components/MyScheduleEvent";
import RenderCustomDay from "./components/RenderCustomDay";
import RenderMyResource from "./components/RenderMyResource";
import RenderWeekDay from "./components/RenderWeekDay";
import {
  getNrDaysFunc,
} from "./util/common";
import { hasOverlap } from "./util/projectview";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { KTSVG } from "../../../../_metronic/helpers";
import RenderMonth from "./components/RenderMonth";
import BulkEditTasks from "../../../components/modals/BulkEditTasks";

momentTimezone.moment = moment;
setOptions({
  theme: "ios",
  themeVariant: "light",
});

const ProjectView: FC<any> = ({
  availability,
  general_settings,
  filterIds,
  resource_data,
  filterArray,
  handleHeaderChange,
  viewclassName,
  isFetchingAvailability,
  eventCalendarRef,
  showviewcomponent,
  viewBtnbackground,
  debouncedRefresh,
  todo_status_serviceCall,
  task_status,
  resource_settings,
  worktype,
  prioritylist,
  issueslist,
  subIssueslist,
  ticket_status,
  layoutAndFiltersData,
  selectActiveLayout,
  phaseList,
  setIsPopupOpen,
  setCurrentYear,
  viewSettingsData,
  refetchAvailability,
  Viewbtncolor,
  refetchLayouts,
  projectData,
  locations,
  openDropdown,
  toggleDropdown,
  currentDate,
  setCurrentDate,
  setOpenDropdown
}) => {
  const navigate = useNavigate()
  const multiselectEnables = true;
  const locationShow = general_settings?.isLocation ?? true;
  const { currentUser } = useAuth();
  const starttime: any = general_settings?.start_time;
  const endtime: any = general_settings?.end_time;
  const time_format: any = general_settings?.time_format;
  const timezone: any = general_settings?.timezone;
  let extendedMorningStart: any = general_settings?.extended_morning_start;
  let extendedMorningEnd: any = general_settings?.extended_morning_end;
  let extendedEveningStart: any = general_settings?.extended_evening_start;
  let extendedEveningEnd: any = general_settings?.extended_evening_end;

  extendedMorningStart = extendedMorningStart ?? starttime;
  extendedMorningEnd = extendedMorningEnd ?? starttime;
  extendedEveningStart = extendedEveningStart ?? endtime;
  extendedEveningEnd = extendedEveningEnd ?? endtime;
  let defaultView: any = viewSettingsData?.view
    ? viewSettingsData?.view
    : general_settings?.planning_screen_view;
  const [view, setView] = useState(defaultView ?? "week");

  const getColumnClassName = () => {
    const { assigned, date, status, hours } =
      viewSettingsData?.columnSelection || {};

    const fields = [assigned, date, status, hours];
    const trueCount = fields.filter(Boolean).length;

    switch (trueCount) {
      case 4:
        return "fourColumnSelected";
      case 3:
        return "threeColumnSelected";
      case 2:
        return "twoColumnSelected";
      case 1:
        return "oneColumnSelected";
      default:
        return "noColumnSelected";
    }
  };
  const renderHoursTooltip = (
    <Popover id="render-hours-tooltip">
      <div className="p-5 bg-white card">
        <div>Capacity is based on different parameters. Estimated hours (task/project), Planned hours (upcoming scheduled service calls), Actual worked (logged time), Hours Left (estimated hours not scheduled with service calls).
           <a href="https://knowledgebase.proxuma.io/article/projects-dashboard" className="text-decoration-underline ms-2" target="_blank">
              Learn more
            </a>
          </div>
        </div> 
    </Popover>
  )
  const renderCustomHeader = () => {
    return (
      <div className="md-resource-header-template-title">
        <div className="md-resource-header-template-name"></div>
        {viewSettingsData?.columnSelection?.assigned && (
          <div className="md-resource-header-template-seats">Assigned</div>
        )}
        {viewSettingsData?.columnSelection?.date && (
          <div className="md-resource-header-template-date"> Dates</div>
        )}
        {viewSettingsData?.columnSelection?.status && (
          <div className="md-resource-header-template-status"> Status</div>
        )}
        {viewSettingsData?.columnSelection?.hours && (
          <div className="md-resource-header-template-hours"> Hours
            <div className="h-32px d-flex align-items-center">
              <OverlayTrigger
                trigger="click"
                rootClose
                placement="right"
                overlay={renderHoursTooltip}
              >
                <button className="btn p-0 text-center rounded-3px border lh-1 border-primary text-primary fs-6 w-10px color-white infoBtn ms-2">i</button>
              </OverlayTrigger>
            </div>
          </div>
        )}
      </div>
    );
  };

  // calculate total duration
  let duration: any = moment.duration(
    moment(endtime, time_format).diff(moment(starttime, time_format))
  );
  // duration in hours
  var hours =
    parseInt(duration.asHours()) + "." + (parseInt(duration.asMinutes()) % 60);
  const [calView, setCalView] = useState<MbscEventcalendarView>({
    timeline: {
      type: "week",
      timeCellStep: viewSettingsData?.columnSize?.hoursPerColumn ?? 60,
      timeLabelStep: viewSettingsData?.columnSize?.hoursPerColumn ?? 60,
      startTime: extendedMorningStart ? extendedMorningStart : starttime,
      endTime: extendedEveningEnd ? extendedEveningEnd : endtime,
      startDay: viewSettingsData?.start ? +viewSettingsData?.start : 1,
      endDay: viewSettingsData?.end ? +viewSettingsData?.end : 5,
      weekNumbers: false,
    },
  });

  //errors
  let [planningTask, setPlanningTask] = useState<any>([]);
  let [sidebarData, setSidebarData] = useState<any>([]);
  let [expandedCompany, setExpandedCompany] = useState<any>([]);
  const [tempEvent, settempEvent] = useState<any>(null);
  const [refDate, setRefDate] = useState<any>();
  const [rangeVal, setRangeVal] = useState<any>([]);
  const [buttonText, setButtonText] = useState<string>();
  const [eventDetail, seteventDetail] = useState<any>();
  const [tempSidebarArray, setTempSidebarArray] = useState<any>([]);
  const [istaskupdated, setistaskupdated] = useState<any>(true);
  const [taskList, setTaskList] = useState(false);
  let [taskTicketId, setTaskTicketid] = useState<any>([])
  //Task popup
  const [istooltipOpen, settooltipOpen] = useState<any>(false);

  //servicecall
  const [isServicecallOpen, setServicecallOpen] = useState<any>(false);
  let [splitEventData, setSplitEventsData] = useState<any>(null);

  //phase
  const [isCreatePhaseOpen, setisCreatePhaseOpen] = useState<any>(false);
  //project
  const [isCreateProjectOpen, setisCreateProjectOpen] = useState<any>(false);
  const [isCreateTaskOpen, setIsCreateTaskOpen] = useState<any>(false);

  const [isSettingOpen, setIsSettingOpen] = useState<any>(false);
  const [mySelectedEvents, setSelectedEvents] = useState<MbscCalendarEvent[]>([]);
  const [isCtrlPressed, setisCtrlPressed] = useState(false);

  const { socketMessage }: any = useContext(webSocketContext);
  let hideOptions = general_settings?.hide_options;

  const websocketCallback = useCallback(
    (event: any) => {
      let eData = JSON.parse(event.data);
      if (eData.organizationId == currentUser?.data?.user?.organizationId) {
        let from = "organization";
        if (eData.userId == currentUser?.data?.user?._id) {
          from = "user";
        }
        if (eData.type == "update_task_changes_failed") {
          updatePlanningItem(eData?.data, from);
        } else if (eData.type == "update_sc_changes_failed") {
          updatePlanningItem(
            eData?.data?.[0] ? eData?.data?.[0] : eData?.data,
            from,
            eData?.dataTodelete,
            eData?.split_generate_id,
            eData.type
          );
        }else if (eData.type == "bulkedit_changes_failed") {
          getPlanningData()
        }
      }
    },
    [socketMessage]
  );

  useEffect(() => {
    if (socketMessage) {
      websocketCallback(socketMessage);
    }
  }, [socketMessage]);

  const handleHideOptionalFunc = (item: any, value: any) => {
    const options = !hideOptions
      ? item
      : item?.filter(
          (trole: any) =>
            parseInt(trole.value) === parseInt(value) || trole.isActive === true
        );

    return options;
  };

  const changeView = (value: any) => {
    let calView2: MbscEventcalendarView;
    switch (value) {
      case "day":
        calView2 = {
          timeline: {
            type: "day",
            timeCellStep: viewSettingsData?.columnSize?.hoursPerColumn ?? 60,
            timeLabelStep: viewSettingsData?.columnSize?.hoursPerColumn ?? 60,
            startTime: extendedMorningStart ? extendedMorningStart : starttime,
            endTime: extendedEveningEnd ? extendedEveningEnd : endtime,
            eventList: viewSettingsData?.showColumnSummaryEvent,
            maxEventStack: "all", //viewSettingsData?.numberOfEvents
          },
        };
        break;
      case "week":
      default:
        calView2 = {
          timeline: {
            type: "week",
            timeCellStep: viewSettingsData?.columnSize?.hoursPerColumn ?? 60,
            timeLabelStep: viewSettingsData?.columnSize?.hoursPerColumn ?? 60,
            eventList: viewSettingsData?.showColumnSummaryEvent,
            startTime: extendedMorningStart ? extendedMorningStart : starttime,
            endTime: extendedEveningEnd ? extendedEveningEnd : endtime,
            weekNumbers: false,
            startDay:(viewSettingsData?.start || viewSettingsData?.start === 0) ? +viewSettingsData?.start : 1,
            endDay: (viewSettingsData?.end || viewSettingsData?.end === 0)  ? +viewSettingsData?.end : 5,
            maxEventStack:"all",
          },
        };
        break;
      case "month":
        calView2 = {
          timeline: {
            type: "month",
            resolutionHorizontal: "week",
            startTime: "00:00",
            endTime: "24:00",
            startDay:(viewSettingsData?.start || viewSettingsData?.start === 0) ? +viewSettingsData?.start : 1,
            endDay: (viewSettingsData?.end || viewSettingsData?.end === 0)  ? +viewSettingsData?.end : 5,
            weekNumbers: true,
            eventList: viewSettingsData?.showColumnSummaryEvent,
            maxEventStack: "all",
          },
        };
        break;
      case "year":
        calView2 = {
          timeline: {
            type: "year",
            resolutionHorizontal: "month",
            size: 1,
            startDay:(viewSettingsData?.start || viewSettingsData?.start === 0) ? +viewSettingsData?.start : 1,
            endDay: (viewSettingsData?.end || viewSettingsData?.end === 0)  ? +viewSettingsData?.end : 5,
            startTime: "00:00",
            endTime: "24:00",
            weekNumbers: true,
            eventList: viewSettingsData?.showColumnSummaryEvent,
            maxEventStack:"all",
          },
        };
        break;
    }
    setView(value);
    setCalView(calView2);
  };

  const updateEvent = useCallback(
    async (event: any) => {
      let e = event;
      let res: any = [];
      res = await updateTasks(e);
      setistaskupdated(true);
      if (!res || res?.status != 200) {
        settooltipOpen(false);
        return false;
      } else {
        updatePlanningItem(event, "user");
        settooltipOpen(false);
        return true;
      }
    },
    [planningTask, availability, eventDetail]
  );
  
  const updateServicecall = useCallback(
    async (eventData: any, splitEvent: any) => {
      let res: any = [];
      let event = JSON.parse(JSON.stringify(eventData));
      event.serviceCallId = event?.autotask_id ? event?.autotask_id : "";
      if (splitEvent && splitEvent.length > 0) {
        let first_event = JSON.parse(JSON.stringify(splitEvent[0]));
        event.start = first_event.start;
        event.end = first_event.end;
      }
      event.isProjectScreen = true;
      if (
        event.split_generate_id != "" &&
        event.split_generate_id != undefined
      ) {
        event.id = "";
      }
      let duration = moment.duration(
        moment(event.end).diff(moment(event.start))
      );
      event.planned = duration.asHours();
      event.duration = duration.asHours();
      event.status_name = todo_status_serviceCall?.filter(
        (status: any) =>
          parseInt(status.id) == parseInt(event.servicecall_status)
      )?.[0]?.name;
      if (event.duration > 0) {
        if (event.id == "") {
          res = await createPopupvalues(event);
        } else {
          res = await updatePopupvalues(event);
        }
      }
      setistaskupdated(true);
      setServicecallOpen(false);
      if (!res || res?.status != 200) {
        if (res?.length != 0 && res?.status != 200) {
          return false;
        }
      } else {
        let planTask: any = planningTask.filter(
          (item: any) => !(item.id == eventData.id)
        );
        let newServicecalls: any = [];
        if (event.id == "") {
          event.id = res.data?.data?.id;
        }else{
          if(res.data?.servicecall?.[0]){
            event.hoursLeft = res.data?.servicecall[0]?.hoursLeft;
            event.hoursToBeScheduled = res.data?.servicecall?.[0]?.hoursToBeScheduled;
            event.estimatedHours = res.data?.servicecall?.[0]?.estimatedHours;
            event.hoursWorked = res.data?.servicecall?.[0]?.hoursWorked;
            event.plannedHours = res.data?.servicecall?.[0]?.plannedHours;
          }
        }
        event.split_generate_id = "";
        event.resource = eventData.resource;
        newServicecalls.push(event);
        if (splitEvent?.length > 0) {
          let splitUpdateArray = await splitedEventCall(splitEvent, resource_settings?.data, "");
          newServicecalls.push(...splitUpdateArray);
        }
        
        let task: any = await getTaskDetailCapsule({
          autotask_id: event?.resource,
        });
        if (task) {
          planTask = planTask.filter(
            (item: any) =>
              !(event.resource.includes(item.resource) && item.type === "task")
          );
          newServicecalls.push(...task);
        }
        setPlanningTask([...planTask, ...newServicecalls]);
        return true;
      }
    },
    [eventDetail, splitEventData, planningTask]
  );

  const splitEventsFn = useCallback(
    async (event1: any, fromwhere: any) => {
      event1.status_name = todo_status_serviceCall?.filter(
        (status: any) =>
          parseInt(status.id) == parseInt(event1.servicecall_status)
      )?.[0]?.name;
      let data: any = await splitServicecallEvents(
        event1,
        starttime,
        endtime,
        timezone
      );
      setSplitEventsData(data.splitData);
      if (fromwhere == "fromdrag") {
        seteventDetail(data.event);
        updateServicecall(data.event, data.splitData);
      }
    },
    [endtime, starttime, splitEventData, eventDetail, planningTask]
  );

  const [isDoubleClick, setisDoubleClick] = useState(false);

  const onEventClick = useCallback(
    async (e: any, args: any) => {
      if (istooltipOpen !== true ) {
        if (args?.type === "task") {
          let currentEvent = await getTaskDetail({
            autotask_id: args?.autotask_id,
          });
          const event = currentEvent;
          event.isUpdate = true;
          if (event?.editable != false || event?.isoffline != true) {
            settooltipOpen(true);
          }
          event.assigntoresource = [];
          event.servicecall_resources = [];
          let task_status1 = JSON.parse(JSON.stringify(task_status));
          task_status1.unshift({
            label: "Select status for task",
            value: "",
          });

          let status_name = task_status?.filter(
            (status: any) => parseInt(status.id) == parseInt(event.status)
          );
          event.status_name = status_name ? status_name[0]?.label : "-";
          event.secondary_resources = event?.secondary_resources?.filter(
            (secondaryres: any) =>
              parseInt(secondaryres.resourceID) !==
              parseInt(event.assignedResourceID)
          );
          let secondary: any = [];
          event?.secondary_resources?.filter(function (item: any) {
            var i = secondary.findIndex(
              (x: any) => x.resourceID == item.resourceID
            );
            if (i <= -1) {
              secondary.push(item);
            }
          });
          event.secondary_resources = secondary;
          if (event.assignedResourceID) {
            event.assigntoresource.push(parseInt(event.assignedResourceID));
            event.servicecall_resources.push(
              parseInt(event.assignedResourceID)
            );
          }
          event.secondary_resources = event?.secondary_resources?.filter(
            (secondaryres: any) =>
              parseInt(secondaryres.resourceID) !==
              parseInt(event.assignedResourceID)
          );
          event?.secondary_resources?.filter((secondaryres: any) => {
            event.assigntoresource.push(parseInt(secondaryres.resourceID));
            event.servicecall_resources.push(parseInt(secondaryres.resourceID));
            secondaryres.value = parseInt(
              secondaryres.resourceID.toString() +
                secondaryres.roleID.toString()
            );
          });
          let isexistwortype = worktype?.filter(
            (trole: any) =>
              parseInt(trole.value) == parseInt(event?.billingCodeID)
          );
          if (isexistwortype?.length == 0) {
            event.billingCodeID = null;
          }
          let isexiststatus = task_status?.filter(
            (trole: any) => parseInt(trole.value) == parseInt(event?.status)
          );
          if (isexiststatus?.length == 0) {
            event.status = null;
          }
          seteventDetail(event);
          if (event?.editable != false) {
            settooltipOpen(true);
          }
          // popupvalidation(event);
          settempEvent(JSON.parse(JSON.stringify(args)));
        } else if (args?.type === "servicecall") {
          setSplitEventsData(null);
          settempEvent(JSON.parse(JSON.stringify(args)));
          let currentEvent: any;
          if (args?.from === "doubleClick") {
            // currentEvent = args;
            currentEvent = await getServicecallDetail({
              task_id: args?.resource,
              isProjectScreen: true,
            });
            setisDoubleClick(false);
            currentEvent.id = args?.id;
            currentEvent.resource = [args?.resource];
            currentEvent.serviceCallId = "";
            currentEvent.start = args?.start;
            currentEvent.end = args?.end;
            currentEvent.split_generate_id = args?.split_generate_id;
            if (
              general_settings?.default_servicecall_status &&
              todo_status_serviceCall?.some(
                (e: any) =>
                  parseInt(e.id) ===
                  parseInt(general_settings?.default_servicecall_status)
              )
            ) {
              currentEvent.servicecall_status =
                general_settings?.default_servicecall_status;
            }
            if (
              general_settings?.default_task_worktype &&
              worktype?.some(
                (e: any) =>
                  parseInt(e.value) ===
                  parseInt(general_settings?.default_task_worktype)
              )
            ) {
              currentEvent["related_tasks"][0].billingCodeID =
                general_settings?.default_task_worktype;
            }
            if (
              general_settings?.default_task_status &&
              task_status?.some(
                (e: any) =>
                  parseInt(e.id) ===
                  parseInt(general_settings?.default_task_status)
              )
            ) {
              currentEvent["related_tasks"][0].status =
                general_settings?.default_task_status;
            }
          } else {
            if (args?.from_copy !== true) {
              currentEvent = await getServicecallDetail({
                id: args?.id,
                isProjectScreen: true,
              });
            }else{
              currentEvent = JSON.parse(JSON.stringify(args));
            }
          }
          currentEvent.resource = args.resource;
          const event: any = currentEvent;
          event.type = "servicecall";
          if (args?.from === "doubleClick") {
            let valid: any = await checkBufferHours(
              event,
              starttime,
              endtime,
              timezone
            );
            if (!valid[0]) {
              let planTask: any = planningTask.filter(
                (item: any) => !(item.id === event.id)
              );
              setPlanningTask([...planTask]);
              return false;
            }
          }
          event.removed_tasks = []
          event.removed_tickets = []
          event.isOvertimeShow = false;
          seteventDetail(event);
          setServicecallOpen(true);
        } else if (args?.type === "project") {
          if (args?.resource) {
            navigate(`/apps/projects/list/edit/${args?.resource}`);
          }
        } else if (args?.type === "phase") {
          if (args?.projectID) {
            navigate(`/apps/projects/list/edit/${args?.projectID}`);
          }
        } else {
          return false;
        }
      }
    },
    [
      eventDetail,
      planningTask,
      availability,
      tempEvent,
      starttime,
      endtime,
      todo_status_serviceCall,
      isDoubleClick,
      istooltipOpen,
      isServicecallOpen,
    ]
  );

  const createEvent = useCallback(
    (args: any, inst: any) => {
      if (
        isDoubleClick === true ||
        istooltipOpen === true ||
        isServicecallOpen === true
      ) {
        return false;
      }
      let filterItems = planningTask.filter(
        (item: any) =>
          item?.resource == args?.event?.resource
      );

      const hasLetter = /[a-zA-Z]/.test(args?.event?.resource);
      const hasNumber = /\d/.test(args?.event?.resource);
      if (filterItems?.length > 0 && !(hasLetter && hasNumber)) {
        let event = args.event;
        event.type = "servicecall";
        event.from = "doubleClick";
        const addstarttime = starttime;
        if (view == "year" || view == "month") {
          let start = moment
            .tz(
              moment(event.start).format("YYYY-MM-DDT") + addstarttime,
              timezone
            )
            .utc()
            .format("YYYY-MM-DDTHH:mm:ss");
          event.start = start + "Z";
        }
        let end = moment(event.start)
          .add(general_settings?.service_call_duration, "hours")
          .utc()
          .format("YYYY-MM-DDTHH:mm");
        event.end = end + "Z";
        event.related_resources = [];
        event.id = (Math.random() + 1).toString(6).substring(6);
        event.split_generate_id = (Math.random() + 1).toString(6).substring(6);
        setPlanningTask([...planningTask, event]);
        setisDoubleClick(true);
        onEventClick("", event);
      } else {
        return false;
      }
    },
    [
      planningTask,
      eventDetail,
      isDoubleClick,
      view,
      istooltipOpen,
      isServicecallOpen,
      onEventClick
    ]
  );

  const ontooptipClose = useCallback(
    async (e: any) => {
      settooltipOpen(false);
      setServicecallOpen(false);
      let planTask: any;
      if (
        eventDetail.serviceCallId == "" &&
        eventDetail.type == "servicecall"
      ) {
        planTask = await planningTask?.filter(
          (item: any) => !(item.id == eventDetail.id)
        );
      } else {
        planTask = await planningTask?.filter(
          (item: any) => !(item.autotask_id == eventDetail.autotask_id)
        );
      }
      setPlanningTask([...planTask, tempEvent]);
      seteventDetail(null);
    },
    [
      eventDetail,
      availability,
      planningTask,
      tempEvent,
      istooltipOpen,
      isServicecallOpen,
    ]
  );

  //const ids = useAppSelector((state) => state.counter);
  let myColors: any = [];
  if (view == "week" || view == "day") {
    let bstart1: any = moment
      .tz(filterIds?.start_date, "YYYY-MM-DD", true, timezone)
      .format("YYYY-MM-DD");
    let bend1: any = moment
      .tz(filterIds?.end_date, "YYYY-MM-DD", true, timezone)
      .format("YYYY-MM-DD");
    let mstart: any =
      bstart1 +
      "T" +
      moment.tz(extendedMorningStart, "HH:mm", true, timezone).format("HH:mm");
    let mend: any =
      bstart1 +
      "T" +
      moment.tz(extendedMorningEnd, "HH:mm", true, timezone).format("HH:mm");
    let estart: any =
      bend1 +
      "T" +
      moment.tz(extendedEveningStart, "HH:mm", true, timezone).format("HH:mm");
    let eend: any =
      bend1 +
      "T" +
      moment.tz(extendedEveningEnd, "HH:mm", true, timezone).format("HH:mm");
    if (parseFloat(hours) <= 22) {
      myColors = [
        {
          start: moment
            .tz(mstart, "YYYY-MM-DDTHH:mm", true, timezone)
            .tz("UTC")
            .format("YYYY-MM-DDTHH:mm"),
          end: moment
            .tz(mend, "YYYY-MM-DDTHH:mm", true, timezone)
            .tz("UTC")
            .format("YYYY-MM-DDTHH:mm"),
          title: "",
          background:
            "#EFEFEF",
          recurring: {
            repeat: "weekly",
            weekDays: "MO,TU,WE,TH,FR,SA,SU",
          },
          cssClass: "md-lunch-break-class mbsc-flex",
        },
        {
          start: moment
            .tz(estart, "YYYY-MM-DDTHH:mm", true, timezone)
            .tz("UTC")
            .format("HH:mm"),
          end: moment
            .tz(eend, "YYYY-MM-DDTHH:mm", true, timezone)
            .tz("UTC")
            .format("HH:mm"),
          title: "",
          background:
            "#EFEFEF",
          recurring: {
            repeat: "weekly",
            weekDays: "MO,TU,WE,TH,FR,SA,SU",
          },
          cssClass: "md-lunch-break-class mbsc-flex",
        },
      ];
    }
  }
  const startDate: any = useRef();
  const endDate: any = useRef();

  // returns the number of days between two dates
  const getNrDays = useCallback((start: any, end: any) => {
    return getNrDaysFunc(start, end);
  }, []);

  // returns the formatted date
  const getFormattedRange = useCallback(
    (start: any, end: any) => {
      return (
        formatDate("MMM D, YYYY", new Date(start)) +
        (end && getNrDays(start, end) > 0
          ? " - " + formatDate("MMM D, YYYY", new Date(end))
          : "")
      );
    },
    [getNrDays]
  );

  const onPageLoading = useCallback(
    (args: any) => {
      const sDate = args.firstDay;
      const end = args.lastDay;
      const eDate = new Date(
        end.getFullYear(),
        end.getMonth(),
        end.getDate() - 1,
        0
      );
      startDate.current = sDate;
      endDate.current = eDate;
      setTimeout(() => {
        setButtonText(getFormattedRange(sDate, eDate));
        setRangeVal([sDate, eDate]);
      });
    },
    [getFormattedRange]
  );

  const onPageLoaded = useCallback(() => {
    debouncedRefresh();
  }, [debouncedRefresh]);

  useEffect(() => {}, [onPageLoaded]);

  const onEventUpdate = useCallback(
    async (args: any, inst: any) => {
      let planTask: any;
      if (
        args?.resource !== args.oldResource ||
        istooltipOpen === true ||
        isServicecallOpen === true
      ) {
        let oldevent = args.oldEvent;
        planTask = planningTask.filter(
          (item: any) => !(item.id === args.event.id)
        );
        setPlanningTask([...planTask, oldevent]);
        return false;
      }
      if (args.event.type == "servicecall") {
        let valid: any = await checkBufferHours(
          args.event,
          starttime,
          endtime,
          timezone
        );
        if (!valid[0]) {
          let oldevent = args.oldEvent;
          let planTask: any = planningTask.filter(
            (item: any) =>
              !(
                item.id === args.event.id &&
                item.type === "servicecall"
              )
          );
          setPlanningTask([...planTask, oldevent]);
          return false;
        }
      }
      let event = JSON.parse(JSON.stringify(args.event));

      if (args.event.type == "servicecall") {
        let currentEvent = await getServicecallDetail({
          id: args.event?.id,
          isProjectScreen: true,
        });
        event = JSON.parse(JSON.stringify(currentEvent));
        event.start = args.event.start;
        event.end = args.event.end;
        event.resource = args.event.resource;
        event.type = args.event.type;
      }
      event.removed_tasks = []
      event.removed_tickets = []
      setSplitEventsData(null);
      seteventDetail(event);
      settempEvent(null);
      if (
        general_settings?.allow_overlapping === false &&
        hasOverlap(args.event, true, planningTask)
      ) {
        toast({
          message: "Overlapping tasks is not allowed",
          display: "center",
        });
        let planTask: any;
        let oldevent = args.oldEvent;
        planTask = planningTask.filter(
          (item: any) => !(item.id == args.event.id)
        );
        setPlanningTask([...planTask, oldevent]);
        return false;
      } else {
        if (
          args.event.type == "servicecall" ||
          event.entity_type == "servicecall" ||
          event.entity_type == "serviceCall"
        ) {
          let hasNoLicenseDetail: any = true;
          let resourceId: any = [];
          if (event?.related_tasks && event?.related_tasks?.length > 0) {
            event?.related_tasks?.filter((stask: any) => {
              if (stask?.assignedResourceID) {
                resourceId.push(parseInt(stask?.assignedResourceID));
              }
              stask.secondary_resources?.filter((secondaryres: any) => {
                resourceId.push(parseInt(secondaryres.resourceID));
              });
            });
          }
          hasNoLicenseDetail = await resourcehasNoLicenseMoreMultiple(
            resourceId,
            resource_settings?.data
          );
          if (hasNoLicenseDetail == false) {
            let oldevent = args.oldEvent;
            planTask = planningTask.filter(
              (item: any) => !(item.id === args.event.id)
            );
            setPlanningTask([...planTask, oldevent]);
            toast({
              message:
                "One of the assigned resources does not have a valid license. Please update the resource license and try again.",
              display: "center",
            });
            return false;
          }
          splitEventsFn(event, "fromdrag");
        } else {
          let hasNoLicenseDetail: any = false;
          let resourceId: any = [];
          if (event?.assignedResourceID) {
            resourceId.push(parseInt(event?.assignedResourceID));
          }
          event.secondary_resources?.filter((secondaryres: any) => {
            resourceId.push(parseInt(secondaryres.resourceID));
          });
          hasNoLicenseDetail = await resourcehasNoLicenseMoreMultiple(
            resourceId,
            resource_settings?.data
          );
          if (hasNoLicenseDetail == false) {
            let oldevent = args.oldEvent;
            planTask = planningTask.filter(
              (item: any) => !(item.id === args.event.id)
            );
            setPlanningTask([...planTask, oldevent]);
            toast({
              message:
                "One of the assigned resources does not have a valid license. Please update the resource license and try again.",
              display: "center",
            });
            return false;
          }
          // format start and end time in UTC
          event.start = moment(event.start).utc().format("YYYY-MM-DDTHH:mm:ss");
          event.start = event.start + "Z";
          event.end = moment(event.end).utc().format("YYYY-MM-DDTHH:mm:ss");
          event.end = event.end + "Z";
          updateEvent(event);
        }
      }
    },
    [
      eventDetail,
      general_settings?.allow_overlapping,
      planningTask,
      tempEvent,
      starttime,
      endtime,
      splitEventData,
      istooltipOpen,
      isServicecallOpen,
    ]
  );

  const updatePlanningItem = useCallback(
    async (
      event: any,
      fromwhere = "user",
      dataTodelete: any = "",
      split_generate_id: any = "",
      type: any = ""
    ) => {
      let planItem: any;
      let planItem1: any;
      planItem1 = planningTask;
      if (fromwhere == "user") {
        if (event && Object.keys(event)?.length > 0) {
          if (event?.entity_id) {
            event.autotask_id = event?.entity_id;
          }
          if (split_generate_id != "") {
            planItem1 = await planItem1.filter(
              (item: any) => !(item?.split_generate_id == split_generate_id)
            );
          }
          if (dataTodelete != "") {
            planItem1 = await planItem1.filter(
              (item: any) => !(item?.id == dataTodelete)
            );
          } else {
            if (event?.type == "task") {
              planItem1 = planItem1.filter(
                (item: any) => !(item?.autotask_id == event?.autotask_id)
              );
            } else {
              planItem1 = planItem1.filter(
                (item: any) => !(item?.id == event?.id)
              );
            }
          }
          if (event?.resource) {
            setPlanningTask([...planItem1, event]);
          }
        } else {
          if (split_generate_id != "") {
            if (type != "update_sc_success") {
              planItem1 = planItem1.filter(
                (item: any) => !(item.split_generate_id == split_generate_id)
              );
            } else {
              let createdServicecall = planItem1.filter(
                (item: any) => item.id == event[0]?.mongo_id
              );

              planItem1 = planItem1.filter(
                (item: any) => !(item.id == event[0]?.mongo_id)
              );
              if (createdServicecall?.length > 0) {
                createdServicecall[0].autotask_id = event[0]?.autotask_id;
                createdServicecall[0].serviceCallId = event[0]?.autotask_id;
                createdServicecall[0].id = event[0]?.mongo_id;
                createdServicecall[0].entity_id = event[0]?.autotask_id;
                createdServicecall[0].split_generate_id = "";
                event = createdServicecall;
              }
            }
          }

          if (dataTodelete != "") {
            planItem1 = planItem1.filter(
              (item: any) => !(item?.id == dataTodelete)
            );
          }
          setPlanningTask(planItem1);
        }
      } else {
        if (event) {
          let sortData: any = planItem1.filter(
            (item: any) => item.autotask_id == event?.autotask_id
          );
          if (sortData.length > 0) {
            planItem = planItem1.filter(
              (item: any) => !(item.autotask_id == event?.autotask_id)
            );
            if (event?.resource) {
              setPlanningTask([...planItem, event]);
            }
          }
        }
      }
    },
    [planningTask]
  );

  const onResourceCollapse = useCallback(
    async (event: any, inst: any) => {
      let filterItems = expandedCompany.filter(
        (item: any) => !(item == event?.resource)
      );
      setExpandedCompany(filterItems);
      let existsidebar = tempSidebarArray?.filter(
        (x: any) => x.id == event?.resource
      );
      if (existsidebar?.length > 0) {
        let index = sidebarData.findIndex((x: any) => x.id == event?.resource);
        let filterSidebarItems = sidebarData.filter(
          (item: any) => !(item.id == event?.resource)
        );
        existsidebar[0].collapsed = true;
        filterSidebarItems.splice(index, 0, existsidebar?.[0]);
        setSidebarData(filterSidebarItems);
        if (planningTask?.length > 0) {
          let companyProjectPlanData = planningTask.filter(
            (item: any) => (parseInt(item.companyID) === parseInt(existsidebar?.[0]?.autotask_id) && ['company','project'].includes(item.type))
          );
          let removedPlanData = planningTask.filter(
            (item: any) => (parseInt(item.companyID) !== parseInt(existsidebar?.[0]?.autotask_id))
          );
          setPlanningTask([...removedPlanData,...companyProjectPlanData]);
        }

        let filterItems1 = tempSidebarArray.filter(
          (item: any) => !(item?.id == event?.resource)
        );
        setTempSidebarArray(filterItems1);
      }
    },
    [expandedCompany, sidebarData, tempSidebarArray, planningTask]
  );

  const onResourceExpand = useCallback(
    async (event: any, inst: any) => {
      let currentResource = sidebarData?.filter(
        (x: any) => x.id == event?.resource
      );
      if (
        currentResource?.length > 0 &&
        currentResource?.[0]?.type == "company"
      ) {
        setTempSidebarArray([...tempSidebarArray, currentResource?.[0]]);
        let payloadData: any = JSON.parse(JSON.stringify(filterIds));
        payloadData.company_id = [event?.resource];

        let currentData:any = [];
        currentData = await getProjectTask(payloadData);
        if (currentData?.data) {
          let index = sidebarData.findIndex(
            (x: any) => x.id == event?.resource
          );
          let filterItems = sidebarData.filter(
            (item: any) => !(item.id == event?.resource)
          );
          filterItems.splice(index, 0, currentData?.data?.[0]);
          if (currentData?.formattedData?.length > 0) {
            let updatePlanData = planningTask.filter(
              (item: any) =>
                parseInt(item.companyID) !==
                parseInt(currentResource?.[0]?.autotask_id)
            );
            setPlanningTask([...updatePlanData, ...currentData?.formattedData]);
          }
          setSidebarData(filterItems);
          let uniqueCompany = expandedCompany.filter(
            (item: any) => !(item == event?.resource)
          );
          setExpandedCompany([...uniqueCompany, event?.resource]);
        }
      }
    },
    [sidebarData, expandedCompany, tempSidebarArray, planningTask]
  );

  useEffect(() => {
    if (isCreateProjectOpen) {
      navigate("/apps/projects/list/create-project");
    }
  }, [isCreateProjectOpen, navigate]);

  function getPlanningData(){
    if (!isFetchingAvailability) {
      if (
        expandedCompany?.length > 0 &&
        (filterIds?.lineOfBusiness?.length == 0 ||
          filterIds?.lineOfBusiness?.length == undefined) &&
        (filterIds?.project_leads?.length == 0 ||
          filterIds?.project_leads?.length == undefined)
      ) {
        let payloadData: any = JSON.parse(JSON.stringify(filterIds));
        if (payloadData?.companies?.length > 0) {
          const companyList = filterArray?.companies
            ?.filter((item: any) => payloadData?.companies.includes(item.id))
            ?.map((item: any) => item.mongo_id);
          let existId = expandedCompany?.filter((e: any) =>
            companyList.includes(e)
          );
          payloadData.company_id = existId;
        } else if (payloadData?.projects?.length > 0) {
          const newCompanies = filterArray?.projects
            ?.filter((item: any) => payloadData?.projects.includes(item.id))
            ?.map((item: any) => item.companyID);
          if (newCompanies?.length > 0) {
            const companyList = filterArray?.companies
              ?.filter((item: any) => newCompanies.includes(item.id))
              ?.map((item: any) => item.mongo_id);
            let existId = expandedCompany?.filter((e: any) =>
              companyList.includes(e)
            );
            payloadData.company_id = existId;
          }
        } else {
          payloadData.company_id = expandedCompany;
        }
        if (payloadData.company_id?.length > 0) {
          getProjectTask(payloadData).then((currentData: any) => {
            let filterItems = resource_data;
            if (currentData?.data) {
              currentData?.data?.map((et: any, etkey: any) => {
                let index = filterItems.findIndex((x: any) => x.id == et?.id);
                filterItems = filterItems.filter(
                  (item: any) => !(item?.id == et?.id)
                );
                filterItems.splice(index, 0, et);
              });
            }
            setSidebarData(filterItems);
            if (currentData?.formattedData?.length > 0) {
              const companyList = filterArray?.companies
                ?.filter((item: any) => payloadData.company_id.includes(item.mongo_id))
                ?.map((item: any) => item.id);
              let companyProjectPlanData =  []
              companyProjectPlanData =  projectData?.formattedData?.filter((item:any) => !(companyList.includes(item.companyID)))
              setPlanningTask([...currentData.formattedData,...companyProjectPlanData]);
            }
            setExpandedCompany(payloadData.company_id);
          });
        }
      } else {
        setExpandedCompany([]);
      }
    }
  }

  useEffect(() => {
    getPlanningData()
    if (isFetchingAvailability && planningTask?.length > 0) {
      setPlanningTask([]);
    }
  }, [isFetchingAvailability]);

  useEffect(() => {
    if (
      !filterArray?.isfetchlayoutandfilters &&
      filterArray?.layoutandfilters?.project_filter?.view_type
    ) {
      const viewType =
        filterArray?.layoutandfilters?.project_filter?.view_type || "week";
      setView(viewType);
      changeView(viewType);
      setCurrentDate(new Date());
    }
  }, [filterArray?.isfetchlayoutandfilters]);

  useEffect(() => {
    if (!isFetchingAvailability) {
      if (Array.isArray(resource_data)) {
        setSidebarData([...resource_data]);
      }
      if (Array.isArray(projectData?.formattedData)) {
        setPlanningTask([...projectData?.formattedData])
      }
    }
  }, [isFetchingAvailability,resource_data]);

  useEffect(() => {
    if (viewSettingsData?.view) {
      changeView(viewSettingsData.view);
    }
  }, [extendedEveningEnd, extendedMorningStart]); 

  useEffect(() => {
    if (viewSettingsData?.view) {
      const updatedView = updateTimelineView(calView, viewSettingsData);
      setCalView(updatedView);
    }
  }, [viewSettingsData]);
  
  const updateTimelineView = (currentView, settings) => {
    let latestView = JSON.parse(JSON.stringify(currentView));
    
    latestView.timeline.startDay = getStartDay(settings);
    latestView.timeline.endDay = getEndDay(settings);

    if ('eventList' in (latestView.timeline || {})) {
      latestView.timeline.eventList = getEventList(settings);
    }

    if ('timeCellStep' in (latestView.timeline || {})) {
      latestView.timeline.timeCellStep = getColumnSize(settings);
    }

    if ('timeLabelStep' in (latestView.timeline || {})) {
      latestView.timeline.timeLabelStep = getColumnSize(settings);
    }
    return latestView;
  };
  
  const getStartDay = (settings) => {
    return (settings?.start || settings?.start === 0) ? +settings.start : 1;
  };
  
  const getEndDay = (settings) => {
    return (settings?.end || settings?.end === 0) ? +settings.end : 5;
  };
  
  const getEventList = (settings) => {
    return settings?.showColumnSummaryEvent ?? null;
  };
  
  const getColumnSize = (settings) => {
    return settings?.columnSize?.hoursPerColumn ?? 60;
  };

  const renderViewSettingsTooltip = (
    <Popover id="render-hours-tooltip">
      <div className="p-5 bg-white card">
        <div>Change how compact your project overview is.<a href="https://knowledgebase.proxuma.io/article/projects-dashboard" className="text-decoration-underline ms-2" target="_blank">
              Learn more
            </a> 
          </div>
        </div> 
    </Popover>
  )

  const bulkEditTasks = useCallback(
    (e: any) => {
        setIsPopupOpen(true);
        setTaskList(true)
    },
    []
  )
  
  const assignedresources = (eventDetail:any) => {
    let event = JSON.parse(JSON.stringify(eventDetail));
    event.assigntoresource = [];
    event.related_tickets?.filter((item1: any) => {
      if (item1.assignedResourceID) {
        event.assigntoresource.push(parseInt(item1.assignedResourceID));
      }
      item1.secondary_resources?.filter((secondaryres: any) => {
        event.assigntoresource.push(parseInt(secondaryres.resourceID));
      });
    });
    event.related_tasks?.filter((item2: any) => {
        if (item2.assignedResourceID) {
          event.assigntoresource.push(parseInt(item2.assignedResourceID));
        }
        item2.secondary_resources?.filter((secondaryres: any) => {
          event.assigntoresource.push(parseInt(secondaryres.resourceID));
        });
    });
    event.servicecall_resources = event?.servicecall_resources?.filter((value: any) =>
      event?.assigntoresource?.includes(value)
    )
    seteventDetail(event)
    // popupvalidation(event)
  }

  const refreshSelectedEvents = useCallback(
    (events) => {
      setSelectedEvents(events);
    },
    [],
  );

  const handleSelectedEventsChange = useCallback(
    (args) => {
      if(multiselectEnables){
        const taskEvents = args.events.filter(event => event.type === "task")
        if(taskEvents.length > 0 && isCtrlPressed) {
          refreshSelectedEvents(taskEvents);
        }else{
          refreshSelectedEvents([]);
        }
      }
    },
    [refreshSelectedEvents,isCtrlPressed,multiselectEnables],
  );

  useEffect(() => {
    document.addEventListener('keydown', function(event) {
    if (event.ctrlKey || event.shiftKey || event.altKey) {
          setisCtrlPressed(true)
        }
    });
    document.addEventListener('keyup', function(event) {
      if (!event.ctrlKey && !event.shiftKey && !event.altKey) {
          setisCtrlPressed(false)
        }
    });
  },[])

  return (
    <>
      <div
        className={`planing_main overflow-hidden h-100 ${viewclassName}`}
        id=""
      >
        <div className="filTop d-flex align-items-center justify-content-end bg-white px-5 border-bottom grayBorder">
          <div className="filterLeft d-flex align-items-center flex-wrap flex-1 row-gap-5">
            <FilterMain filterArray={filterArray} />
            
          </div>
            <div
              className="cursor-pointer d-flex align-items-center me-2 fs-16 view-settings-project"
              id="kt_todo_plan_close"
              onClick={showviewcomponent}

            >
              <KTSVG
                path="/media/icons/duotune/new-icons/settings.svg"
                className="me-1"
                stylecode={{ color: `${Viewbtncolor}` }}
                svgClassName="w-auto h-auto"
              />
              <span>Settings</span>
            </div>
    
            <div className="h-32px d-flex align-items-center">
              <OverlayTrigger
                trigger="click"
                rootClose
                placement="bottom"
                overlay={renderViewSettingsTooltip}
              >
                <button className="btn p-0 text-center rounded-3px border lh-1 border-primary text-primary fs-6 w-10px color-white infoBtn me-3 ms-2">i</button>
              </OverlayTrigger>
            </div>
            
                {/* bulk Task edit button  start */}
                <div className="filterBtns">
            <div className="d-flex align-items-center">              
              <button
                className={`btn btn-btnGray d-inline-flex align-items-center p-0 px-4 h-32px rounded-4px me-5 fs-16 ${multiselectEnables ? "" : "d-none" }`}
                type="button"
                data-kt-menu-trigger="click"
                data-kt-menu-attach="parent"
                data-kt-menu-placement="bottom-end"
                data-kt-menu-flip="bottom"
                onClick={bulkEditTasks}
              >
              <KTSVG
                path="/media/icons/duotune/new-icons/pencil-icon-new.svg"
                className="me-3"
                svgClassName="w-auto h-auto"
              />
              <small className="fs-100">Bulk Edit</small>
              </button>
            </div>
          </div>    
            {/* bulk Task edit button  end */}

          <CreateButton
            setisCreatePhaseOpen={setisCreatePhaseOpen}
            isCreateProjectOpen={isCreateProjectOpen}
            isSettingOpen={isSettingOpen}
            setIsSettingOpen={setIsSettingOpen}
            setIsPopupOpen={setIsPopupOpen}
            setisCreateProjectOpen={setisCreateProjectOpen}
            isCreatePhaseOpen={isCreatePhaseOpen}
            setIsCreateTaskOpen={setIsCreateTaskOpen}
          />
        </div>

        <div
          className={`planningScreen m-0 ${getColumnClassName()} ${view == "month" || view == "year" && "yearView"}`}
        >
          {/* Calender */}
          <div className="mbsc-grid mbsc-no-padding p-0 projectScreen">
            <div className="mbsc-row m-0" id="event-calendar">
              <div className="mbsc-col-sm-12 dynamically-color-and-invalidate-calendar">
                <div
                  className="mbsc-timeline-grid-scroll mbsc-flex-col
                                    mbsc-flex-1-1 mbsc-ios mbsc-ltr"
                >
                  {sidebarData?.length !== 0 || sidebarData.length > 0 ? (
                    <>
              <Page className="md-bulk-operations">
                    <div id="project-page">
                      <Eventcalendar
                        showEventTooltip={false}
                        firstDay={1}
                        className="md-work-order-scheduling"
                        theme="ios"
                        themeVariant="light"
                        dataTimezone="utc"
                        ref={eventCalendarRef}  
                        eventOverlap={true}
                        selectedDate={currentDate}
                        defaultSelectedDate={currentDate}
                        displayTimezone={
                          view === "month" &&
                          viewSettingsData?.showColumnSummaryEvent === true
                            ? ""
                            : timezone
                        }
                        timezonePlugin={momentTimezone}
                        view={calView}
                        //data={planningTask}
                        data={
                          viewSettingsData?.showServicecall == false
                            ? planningTask?.filter(
                                (item: any) => !(item.type == "servicecall")
                              )
                            : planningTask?.filter((item) => item)
                        }
                        resources={sidebarData}
                        renderResource={(resource) => (
                          <RenderMyResource
                            resource={resource}
                            timezone={timezone}
                            ticket_status={ticket_status}
                            project_status={filterArray?.project_status}
                            handleHideOptionalFunc={handleHideOptionalFunc}
                            refetchAvailability={refetchAvailability}
                            viewSettings={viewSettingsData}
                          />
                        )}
                        renderScheduleEvent={(data) => (
                          <MyScheduleEvent
                            data={data}
                            task_status={task_status}
                            todo_status_serviceCall={todo_status_serviceCall}
                            worktype={worktype}
                            general_settings={general_settings}
                            onEventClick={onEventClick}
                            timezone={timezone}
                            view={view}
                            viewSettings={viewSettingsData}
                            locationShow={locationShow}
                            locations={locations}
                            prioritylist={prioritylist}
                            lineofbusinessList={filterArray?.lineOfBusiness}
                            projectTypeList={filterArray?.projectTypeList}
                            resourceList={filterArray?.peoples}
                            companyList={filterArray?.companies}
                            multiSelectEvents={mySelectedEvents}
                            multiselectEnable={multiselectEnables}
                          />
                        )}
                        renderHeader={() => (
                          <CustomWithNavButtons
                            buttonText={buttonText}
                            currentDate={currentDate}
                            startDate={startDate}
                            endDate={endDate}
                            setCurrentDate={setCurrentDate}
                            setRefDate={setRefDate}
                            setCalView={setCalView}
                            extendedMorningStart={extendedMorningStart}
                            extendedEveningEnd={extendedEveningEnd}
                            setRangeVal={setRangeVal}
                            rangeVal={rangeVal}
                            view={view}
                            setView={setView}
                            starttime={starttime}
                            endtime={endtime}
                            filterArray={filterArray}
                            setCurrentYear={setCurrentYear}
                            showviewcomponent={showviewcomponent}
                            viewBtnbackground={viewBtnbackground}
                            viewSettingsData={viewSettingsData}
                            Viewbtncolor={Viewbtncolor}
                            layoutAndFiltersData={layoutAndFiltersData}
                            selectActiveLayout={selectActiveLayout}
                            refetchLayouts={refetchLayouts}
                            filterIds={filterIds}
                            toggleDropdown={toggleDropdown}
                            openDropdown ={openDropdown}
                            changeView={changeView}
                          />
                        )}
                        onPageLoading={onPageLoading}
                        onPageLoaded={onPageLoaded}
                        onPageChange={handleHeaderChange}
                        dragToMove={true}
                        dragToResize={true}
                        dragBetweenResources={false}
                        onEventUpdate={onEventUpdate}
                        clickToCreate="double"
                        onEventCreate={createEvent}
                        cssClass="md-event-listing"
                        colors={
                          viewSettingsData?.showColumnSummaryEvent === true
                            ? []
                            : myColors
                        }
                        refDate={refDate}
                        renderDay={(args) => (
                          <RenderCustomDay
                            args={args}
                            view={view}
                            formatDate={formatDate}
                          />
                        )}
                        renderResourceHeader={renderCustomHeader}
                        renderWeek={(args) => (
                          <RenderWeekDay args={args} view={view} />
                        )}
                          renderMonth={(args) => (
                            <RenderMonth args={args} view={view} />
                          )}
                        onResourceExpand={onResourceExpand}
                        onResourceCollapse={onResourceCollapse}
                        dragTimeStep={15}
                        timeFormat={
                          time_format == "HH:mm" ? time_format : "hh:mm a"
                        }
                        todayText="To Today"
                        selectMultipleEvents={multiselectEnables}
                        selectedEvents={mySelectedEvents}
                        onSelectedEventsChange={handleSelectedEventsChange}
                      />
                      </div>
                      </Page>
                    </>
                  ) : (
                    <>
                      <span
                        style={{
                          fontWeight: "500",
                          textAlign: "center",
                          marginTop: "22%",
                          left: "50px",
                          color: "red",
                          fontSize: "18px",
                        }}
                      >
                        There are no items to display.
                      </span>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
          {/* End::Calendar */}
        </div>
        {istooltipOpen && (
          <TaskUpdate
            planningTask={planningTask}
            istooltipOpen={istooltipOpen}
            eventDetail={eventDetail}
            screenName="project"
            seteventDetail={seteventDetail}
            tempEvent={tempEvent}
            setistaskupdated={setistaskupdated}
            istaskupdated={istaskupdated}
            ontooptipClose={ontooptipClose}
            general_settings={general_settings}
            timezone={timezone}
            hasOverlap={(args: any, inst: any) => {
              hasOverlap(args, inst, planningTask);
            }}
            worktype={worktype}
            time_format={time_format}
            todo_status_serviceCall={todo_status_serviceCall}
            resource_settings={resource_settings}
            task_status={task_status}
            settooltipOpen={settooltipOpen}
            setPlanningTask={setPlanningTask}
            locationShow={locationShow}
            locations={locations}
          ></TaskUpdate>
        )}

        {/* edit servicecall popup */}
        {isServicecallOpen && (
          <ServicecallUpdate
            planningTask={planningTask}
            setPlanningTask={setPlanningTask}
            istooltipOpen={isServicecallOpen}
            settooltipOpen={setServicecallOpen}
            isEventDetailPopup={true}
            eventDetail={eventDetail}
            screenName="project"
            general_settings={general_settings}
            timezone={timezone}
            seteventDetail={seteventDetail}
            splitEventsFn={splitEventsFn}
            splitEventData={splitEventData}
            tempEvent={tempEvent}
            settempEvent={settempEvent}
            onEventClick={onEventClick}
            tempArgsEvent={[]}
            settempArgsEvent={""}
            onEventUpdate={onEventUpdate}
            setistaskupdated={setistaskupdated}
            istaskupdated={istaskupdated}
            onClose={ontooptipClose}
            issueslist={issueslist}
            subIssueslist={subIssueslist}
            worktype={worktype}
            prioritylist={prioritylist}
            time_format={time_format}
            todo_status_serviceCall={todo_status_serviceCall}
            resource_settings={resource_settings}
            task_status={task_status}
            ticket_status={ticket_status}
            locationShow={locationShow}
            locations={locations}
            filterIds={filterIds}
            openDropdown ={openDropdown}
            toggleDropdown={toggleDropdown}
            filterArray={filterArray}
          />
        )}

        {/*end edit servicecall popup */}

        {/* {create project} */}
        {isCreatePhaseOpen && (
          <PhaseCreate
            isOpen={isCreatePhaseOpen}
            setisCreatePhaseOpen={setisCreatePhaseOpen}
            time_format={time_format}
            timezone={timezone}
            projectList={filterArray?.projects}
            phaseList={phaseList}
            setIsPopupOpen={setIsPopupOpen}
          />
        )}

        {isCreateTaskOpen && (
          <TaskCreate
            isOpen={isCreateTaskOpen}
            setIsCreateTaskOpen={setIsCreateTaskOpen}
            time_format={time_format}
            timezone={timezone}
            projectList={filterArray?.projects}
            general_settings={general_settings}
            resource_settings={resource_settings}
            issueslist={issueslist}
            subIssueslist={subIssueslist}
            worktype={worktype}
            phase={phaseList}
            filterArray={filterArray}
            prioritylist={prioritylist}
            setIsPopupOpen={setIsPopupOpen}
            locationShow={locationShow}
            locations={locations}
          />
        )}

        {/* for bulk task creation */}
     {taskList && (
      <BulkEditTasks
        eventDetail={eventDetail}
        taskList={taskList}
        setTaskList={setTaskList}
        seteventDetail={seteventDetail}
        filterArray={filterArray} 
        view={view} 
        openDropdown={openDropdown} 
        setOpenDropdown = {setOpenDropdown}
        toggleDropdown={toggleDropdown} 
        general_settings={general_settings}
        taskListType={"Task"}
        taskTicketId={taskTicketId}
        setTaskTicketid={setTaskTicketid}
        filterIds={filterIds}
        assignedresources={assignedresources}
        mySelectedEvents={mySelectedEvents}
        setSelectedEvents={setSelectedEvents}
      />
    )}
      </div>
    </>
  );
};
export default ProjectView;
